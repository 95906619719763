 
import { 
	LOGIN_ACTIONS, SAGA_GET_REQUEST_API, SAGA_RECEIVE_DATA, SAGA_GET_COMMENTS_BY_ID, SAGA_GET_RECEIVE_COMMENTS_BY_ID,
  } from "../../constants/actions";
  
  export const loginUser = (user) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_USER,
      user
    }
  }

  export const receiveAuthLoginUser = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_AUTH_LOGIN_USER,
      response
    }
  }

  export const resetDisabledLogin = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_RESET_DISABLED
    }
  }

  export const getLoginByCodeUser = (code) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATION_BY_CODE,
      code
    }
  }

  export const receiveGetLoginByCodeUser = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_VALIDATION_BY_CODE,
      response
    }
  }

  export const setRole = (role) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_SET_ROLE,
      role
    }
  }
  
  export const handleUserInfo = (id, value) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_HANDLE_USER_INFO,
      id,
      value
    }
  }
  
  export const handleObjFiltered = (id, value) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_HANDLE_FIELDS_FILTEREDS,
      id,
      value
    }
	}
	
	export const resetLogin = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_RESET,
    }
  }

  export const validateAllFields = (result) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGIN_VALIDATE_FIELDS,
      result
    }
  }

  export const resetValidateFields = (field) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALIDATE_FIELDS,
      field
    }
  }

  export const handleCode = (code) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_HANDLE_CODE,
      code
    }
  }

  export const sendCode = (token, code) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_SEND_CODE,
      token,
      code
    }
  }

  export const receiveResponseSendCode = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_PUT_LOGIN_CODE,
      response
    }
  }

  export const resetValidationChallenge = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALIDATION_CHALLENGE
    }
  }

  export const counter = (attemp) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_COUNTER_ATTEMPS,
      attemp
    }
  }

  export const clearLocalStorage = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGOUT
    }
  }

  export const showPassword = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_SHOW_PASSWORD
    }
  }

  export const sendResetPassword = (email) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_PASSWORD,
      email
    }
  }

  export const receiveResponseResetPassword = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_RESPONSE_RESET_PASSWORD,
      response
    }
  }
  
  export const resetValuesRecoveryPassword = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALUES_RECOVERY_PASSWORD
    }
  }

  export const listAcquirersByUser = (token) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_ACQUIRERS_AVAILABLE,
      token
    }
  }

  export const listIssuersByUser = (token) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_ISSUERS_AVAILABLE,
      token
    }
  }

  export const receiveResponseListAcquirersByUser = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ACQUIRERS_AVAILABLE,
      response
    }
  } 

  export const receiveResponseListIssuersByUser = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ISSUERS_AVAILABLE,
      response
    }
  }

  export const reSendCode = (email) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESENT_CODE,
      email
    }
  } 

  export const receiveResponserReSendCode = (response) => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RECEIVE_RESPONSE_RESENT_CODE,
      response
    }
  }

  export const resetValuesReSentCode = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALUES_RESENT_CODE,      
    }
  }

  export const resetValuesAuth = () => {
    return {
      type: LOGIN_ACTIONS.LOGIN_ACTIONS_RESET_VALUES,      
    }
  }
