import { WHITELIST_ACTIONS } from "../../constants/actions"

  export const updateCommerce = (payload) => {
    return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_UPDATE_COMMERCE,
      payload
    }
  }

  export const receiveUpdateCommerce = (response) => {
    return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_RECEIVE_UPDATE_COMMERCE,
      response
    }
  }

  export const resetUpdateCommerce = () => {
    return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_RESET_UPDATE_COMMERCE
    }
  }

  export const fetchWhiteListByCard = (card, token, page, issuerSelected) => {
      return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_WHITELIST_BY_CARD,
      card,
      token,
      page,
      issuerSelected
      }
  }

  export const cleanWhiteListByCard =() => {
    return {
      type:WHITELIST_ACTIONS.WHITELIST_ACTIONS_CLEAN_WHITELIST_BY_CARD
    }
  }
  
  export const receiveFetchWhiteListByCard = (response) => {
      return {
          type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_RECEIVE_FETCH_WHITELIST_BY_CARD,
          response
      }
  }
  
  export const fetchWhiteListByCardNextPage = () => {
    return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_NEXT_PAGE_WHITELIST_BY_CARD
    }
  }
  
  export const fetchWhiteListByCardBackPage = () => {
    return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_BACK_PAGE_WHITELIST_BY_CARD
    }
  }
  
  export const fetchWhiteListByCardGoToPage = (page) => {
    return {
      type: WHITELIST_ACTIONS.WHITELIST_ACTIONS_FETCH_GOTO_PAGE_WHITELIST_BY_CARD,
      page: page,
    }
  }