import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootSaga from './sagas';

import auth from './redux/reducers/auth';
import home from './redux/reducers/home';
import users from './redux/reducers/users';
import merchants from './redux/reducers/merchants';
import query from './redux/reducers/query';
import queryauthentication from './redux/reducers/queryauthentication'
import queryoperation from './redux/reducers/queryoperation'
import whitelist from './redux/reducers/whitelist'
import querycard from './redux/reducers/querycard'
import enroll from './redux/reducers/enroll'
import stats from './redux/reducers/stats'

const sagaMiddleware = createSagaMiddleware();
export default createStore(combineReducers({
    auth,
    home,
    merchants,
    users,
    query,
    queryauthentication,
    queryoperation,
    whitelist,
    querycard,
    enroll,
    stats
}), composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
