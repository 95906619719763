import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { ProtectedRoute, isAuthenticated, checkUserToken, getInfoUserSession, getUserFromToken, getIssuerList } from './main/auth/helpers';
import { Button } from 'antd';
import { getSessionStatus } from './services/services';
import { useTranslation } from 'react-i18next';

const HomeView = React.lazy(() => import('./main/home/home.route'));
const LoginView = React.lazy(() => import('./main/auth/auth.route'));

checkUserToken();

const Root: React.FC<any> = ({ user }) => {
  const [t] = useTranslation();
  let counter = 120;
  let bloquear_tmp = false;
  
  setInterval(() => {
    const currentdate = new Date();
    const token = localStorage.getItem('token');
    // console.log('Session SCM - ' + token, currentdate);

    if (token) {
      // console.log('Session SCM - Checking Session ... ');
      const promise = getSessionStatus(token);
      // console.log('Session SCM -', promise);
      promise.then(
        res => {
          // console.log('Session SCM -', res);
          if (res !== undefined) {
            const blocked = localStorage.getItem('session-modal');
            if (blocked !== 'blocked') {
              if (res.success && res.session_status === 2 && !bloquear_tmp) {
                // console.log('Session SCM - Entro 2');
                const modal = document.getElementById("modal-session-waiting");
                if (modal) {
                  modal.style.display = "block";
                  localStorage.setItem('session-modal', 'block');
                }
              }
  
              if (res.success && res.session_status === 1) {
                // console.log('Session SCM - Entro 1');
                bloquear_tmp = false;
                const modal = document.getElementById("modal-session-waiting");
                if (modal) {
                  modal.style.display = "none";
                  localStorage.setItem('session-modal', 'none');
                }

                const modal_finishing = document.getElementById("modal-session-finishing");
                if (modal_finishing) {
                  modal_finishing.style.display = "none";
                }
              }
  
              if (res.success && res.session_status === 0) {
                // console.log('Session SCM - Entro 0');
                const modal = document.getElementById("modal-session-waiting");
                if (modal) {
                  modal.style.display = "none";
                  localStorage.setItem('session-modal', 'none');
                }
                const modal_finishing = document.getElementById("modal-session-finishing");
                if (modal_finishing) {
                  modal_finishing.style.display = "block";
                }
                localStorage.clear();
              }
            }
          } else {
            console.log('Session SCM - [NULL]');
          }
        }
      );
    } else {
      localStorage.removeItem('session-modal');
      // console.log('Session SCM - Without Session ... ');
    }
  }, 10000);


  setInterval(() => {
    const currentdate = new Date();
    // console.log('Session SCM - By second ', currentdate);
    const modal_session = localStorage.getItem('session-modal');
    if (modal_session) {
      // console.log('Session SCM - By second - ', modal_session);
      if (modal_session === 'block') counter--;
      if (modal_session === 'none') counter = 120;
      if (modal_session === 'blocked') counter = 0;

      if (counter === 0) {
        // console.log('Session SCM - By second - Counter ZERO');
        localStorage.setItem('session-modal', 'blocked');
        const modal_waiting = document.getElementById("modal-session-waiting");
        if (modal_waiting) {
          modal_waiting.style.display = "none";
        }
        const modal_finishing = document.getElementById("modal-session-finishing");
        if (modal_finishing) {
          modal_finishing.style.display = "block";
        }
      }
      
      if (counter < 120) {
        // console.log('Session SCM - By second - Counter INCOMING');
        const text_waiting = document.getElementById("text-counter");
        if (text_waiting) {
          if (counter === 1) {
            text_waiting.innerHTML = t('segundo');
          } else {
            text_waiting.innerHTML = t('segundos');
          }
        }
        // console.log('Session SCM - By second - Counter NON ZERO');
        const span = document.getElementById('counter');
        if (span) span.innerHTML = counter.toString();
      }
    }
  }, 1000);
  

  const sessionClose = () => {
    window.location.href = "/login"
  }

  const sessionKeep = () => {
    counter = 120;
    bloquear_tmp = true;
    getIssuerList();
    localStorage.removeItem('session-modal');
    const modal_waiting = document.getElementById("modal-session-waiting");
    if (modal_waiting) {
      modal_waiting.style.display = "none";
    }

    const text_waiting = document.getElementById("text-counter");
    if (text_waiting) {
      text_waiting.innerHTML = t('segundos');
    }

    const span = document.getElementById('counter');
    if (span) span.innerHTML = counter.toString();
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to={isAuthenticated() ? '/home/query/authentication' : '/login'}/>
          </Route>
          <Route component={LoginView} path='/login' />
          <Route component={HomeView} path='/home' />

          {/* {ProtectedRoute('/home', HomeView, user, ['administrator'])} */}

          {/* <Redirect from={'/'} to={isAuthenticated() ? '/home' : '/login'}/> */}
        </Switch>
      </Router>
      <div id="modal-session-waiting" className="modal-session">
        <div className="modal-content-session">
          <p className="modal-close-session-title">{t('Hola, tu sesión va a expirar en:')}</p>
          <p className="modal-close-session-counter"><span className="counter" id="counter">120</span><br /><span id="text-counter">{t('segundos')}</span></p>
          <Button className="modal-close-session-btn-accept btn -medium -primary js-filterButton" type="primary" onClick={sessionKeep}>{t('Continuar Sesión')}</Button>
        </div>
      </div>
      <div id="modal-session-finishing" className="modal-session">
        <div className="modal-content-session">
          <p className="modal-close-session-title">{t('Tu sesión ha expirado')}</p>
          <Button className="modal-close-session-btn-accept btn -medium -primary js-filterButton" type="primary" onClick={sessionClose}>{t('Aceptar')}</Button>
        </div>
      </div>
    </Suspense>
  );
};

function mapStateToProps (state) {
  return {
    userPermissions: state.auth.userPermissions,
  };
}

export default connect(mapStateToProps)(Root);


// export default connect(mapStateToProps, mapDispatchToProps)(Root);