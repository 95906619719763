import { USERS_ACTIONS } from "../../constants/actions"

export const handleValuesUserFilter = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES,
    id,
    value
  }
}

export const getListUsers = (token, issuerSelected, pageCurrentDashboard, objUsersFiltered) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_LIST_USERS,
    token,
    issuerSelected,
    pageCurrentDashboard,
    objUsersFiltered
  }
}

export const receiveresponseGetListUsers = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_LIST_USERS,
    response
  }
}


export const getUserInfo = (token, idUser, issuerSelected) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_GET_INFO,
    token,
    idUser,
    issuerSelected
  }
}

export const receiveresponseGetUserInfo = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFO,
    response
  }
}

export const handleFieldsNewUser = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_HANDLE_USER_INVITED_FIELDS,
    id,
    value
  }
}

export const sentInvitation = (newUser, token, issuerSelected) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_SENT_INVITATION,
    newUser,
    token,
    issuerSelected
  }
}

export const receiveResponseSentInvitation = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_SENT_INVITATION,
    response
  }
}

export const updateListUsers = (list) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_LIST_USERS,
    list
  }
}

export const resetValues = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES,
  }
}

export const getAssociateDetail = (token, id, pageCurrent) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_GET_ASSOCIATE_DETAIL,
    token,
    id,
    pageCurrent
  }
}

export const receiveresponseGetAssociateDetail = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_GET_ASSOCIATE_DETAIL,
    response
  }
}

export const nextPage = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_NEXT_PAGE
  }
}

export const backPage = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_BACK_PAGE
  }
}


export const nextPageDashboard = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_NEXT_PAGE
  }
}

export const backPageDashboard = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_DASHBOARD_BACK_PAGE
  }
}


export const getInfoUserProfile = () => {
  return {
    type: USERS_ACTIONS.USER_ACTIONS_GET_INFO_PROFILE
  }
}


export const receiveResponseGetInfoUserProfile = () => {
  return {
    type: USERS_ACTIONS.USER_ACTIONS_RECEIVE_RESPONSE_GET_INFO_PROFILE
  }
}

export const cancelInvitation = (token, issuerSelected, idUser) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_CANCEL_INVITATION,
    token,
    issuerSelected,
    idUser
  }
}
export const receiveResponseCancelInvitation = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_CANCEL_INVITATION,
    response
  }
}

export const updateImage = (token, issuerSelected, base64, idUser) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE,
    token,
    issuerSelected,
    base64,
    idUser
  }
}
export const receiveResponseUpdateImage = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_IMAGE_PICTURE_USER_ACTIVE,
    response
  }
}

export const resentInvitation = (token, issuerSelected, idUser, role) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESENT_INVITATION,
    token,
    issuerSelected,
    idUser,
    role
  }
}
export const receiveResponseResentInvitation = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_RESENT_INVITATION,
    response
  }
}

export const deleteUser = (token, issuerSelected, idUser) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_DELETE_USER,
    token,
    issuerSelected,
    idUser
  }
}
export const receiveResponseDeleteUser = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_DELETE_USER,
    response
  }
}

export const updateUserInfo = (token, issuerSelected, user) => {
  console.log("aca")
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_INFO,
    token,
    issuerSelected,
    user
  }
}
export const receiveResponseUpdateUserInfo = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO,
    response
  }
}

export const resetValuesIImageUploaded = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_VALUES_UPLOAD_IMAGE
  }
}

export const handleValuesUpdateInfoUser = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_HANDLE_VALUES_UPDATE_INFO_USER,
    id,
    value
  }
}

export const handleValuesUpdateInfoUserActive = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_UPDATE_USER_ACTIVE,
    id,
    value
  }
}

export const goToPage = (filters, pageCurrentDashboard, token, issuerSelected) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_GO_TO_PAGE,
    filters,
    pageCurrentDashboard,
    token,
    issuerSelected
  }
}

export const setUserProfileData = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_SET_PROFILE_DATA,
    response
  }
}

export const handleValuesProfileUpdateData = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA_HANDLE,
    id,
    value
  }
}

export const resetValuesProfileData = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_UPDATE_DATA_VALUES
  }
}

export const resetValuesProfilePassword = () => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_RESET_UPDATE_PASSWORD_VALUES
  }
}

export const updateProfileData = (token, issuerSelected, user, user_key) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA,
    token,
    issuerSelected,
    user,
    user_key
  }
}

export const receiveUpdateProfileData = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_DATA_RECEIVE,
    response
  }
}

export const handleValuesProfilePassword = (id, value) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_HANDLE,
    id,
    value
  }
}

export const updateProfilePassword = (token, issuerSelected, user, user_key) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD,
    token,
    issuerSelected,
    user,
    user_key
  }
}

export const receiveUpdateProfilePassword = (response) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RECEIVE,
    response
  }
}

export const deleteFilterSelected = (filters, token, page, issuerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_DELETE_FILTERS,
    filters,
    token,
    page,
    issuerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const changeOrder = (field, direction, token, issuerSelected, pageCurrentDashboard, objUsersFiltered) => {
  return {
    type: USERS_ACTIONS.USERS_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    token,
    issuerSelected,
    pageCurrentDashboard,
    objUsersFiltered
  }
}