import { AUTHENTICATION_ACTIONS } from "../../constants/actions";
import { defaultValuesAuthentication } from "../../constants/constants";
import Transform from "../../utils/transform";

let defaultState = defaultValuesAuthentication();

const queryauthentication = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_GET_TRANSACTION:
      return {
        ...state
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_GET_TRANSACTION:
      return {
        ...state,
        responseAuthenticationInfo: action.response,
        isLoadedGetInfoAuthentication: true,
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS: {
      let propertiesArray = []
      let newList = [];
      propertiesArray = Object.keys(state.objFiltered)
      propertiesArray.forEach(e => {
        if (state.objFiltered && state.objFiltered[e]) {
          newList.push(e);
        }
      })
      // console.log(newList)
      // console.log(state.objFiltered)
      return {
        ...state,
        // filters: action.filters,
        searchingFilters: (newList && newList.length > 1) ? true : false,
        isSearching: true,
        authenticationsList: null,
        responseAuthenticationsList: null,
        changePage: false,
        pageCurrent: action.page
      }
    }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_EXPORT_FILE: {
      return {
        ...state,
        exporting: true
      }
    }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: action.response
      }
    }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RESET_EXPORT_FILE: {
      return {
        ...state,
        exporting: false,
        responseExport: null
      }
    }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_LIST_TRANSACTIONS:
      return {
        ...state,
        responseAuthenticationsList: action.response,
        isLoadedAuthentications: true,
        changePage: false,
        authenticationsList: action.response.data,
        searchingFilters: false,
        isSearching: false
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_NEXT_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        changePage: true,
        authenticationsList: null,
        responseAuthenticationsList: null
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_BACK_PAGE:
      return {
        ...state,
        pageCurrent: state.pageCurrent - 1,
        changePage: true,
        authenticationsList: null,
        responseAuthenticationsList: null
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_GO_TO_PAGE:
      return {
        ...state,
        pageCurrent: action.page,
        changePage: true,
        responseAuthenticationsList: null
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FETCH_NEXT_PAGE_TRANSACTIONS_BY_CARD:
      return {
        ...state,
        pageCurrentTransactions: state.pageCurrentTransactions + 1,
        responseTransactionsByCard: null,
        isLoadingTransactionsByCard: false,
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FETCH_BACK_PAGE_TRANSACTIONS_BY_CARD:
      return {
        ...state,
        pageCurrentTransactions: state.pageCurrentTransactions - 1,
        responseTransactionsByCard: null,
        isLoadingTransactionsByCard: false,
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FETCH_GOTO_PAGE_TRANSACTIONS_BY_CARD:
      return {
        ...state,
        pageCurrentTransactions: action.page,
        responseTransactionsByCard: null,
        isLoadingTransactionsByCard: false,
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_PAGE:
      return {
        ...state,
        pageCurrent: 1
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_FILTERS:
      return {
        ...state,
        objFiltered: {
          fromDateAuthentication: '',
          toDateAuthentication: '',
          authResultAuthentication: '',
          eciAuthentication: '',
          currencyAuthentication: '',
          amountMinAuthentication: '',
          amountMaxAuthentication: '',
          codeAuthentication: '',
          brandAuthentication: '',
          maskTypeAuthentication: 'Enmascarada',
          inclearTypeAuthentication: '',
          binAuthentication: '',
          lastPanAuthentication: '',
          cardNumberAuthentication: '',
          statusCardAuthentication: '',
          nameAuthentication: '',
          channelAuthentication: '',
          authTypeAuthentication: '',
          authValueVersion: ''
        },
        responseAuthenticationsList: null,
        authenticationsList: null
      }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_HANDLE_FILTERS: {
      const aux = { ...state.objFiltered };
      // console.log(action)
      // console.log(aux)

      if ((action.id === 'binAuthentication' && aux.binAuthentication.length === 16) && aux.maskTypeAuthentication === "Enmascarada") {
        const result = Transform.encryptNumber(Number(aux.binAuthentication))
        aux[action.id] = result
      }
      else if (action.id === "maskTypeAuthentication" && aux.binAuthentication.length === 16) {
        console.log("aquio")
        const result = Transform.encryptNumber(Number(aux.binAuthentication))
        aux.binAuthentication = result
      }
      else {
        aux[action.id] = action.value;
      }

      if(action.id === 'maskTypeAuthentication') {
        aux['cardNumberAuthentication'] = "";
      }

      if (action.id === 'inclearTypeAuthentication') {
        aux['binAuthentication'] = "";
        aux['lastPanAuthentication'] = "";
      }

      return {
        ...state,
        objFiltered: aux
      }
    }

    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_DELETE_FILTERS: {
      const aux = { ...state.objFiltered };
      // console.log(action)
      // console.log(aux)
      aux[action.propertyName] = ''
      return {
        ...state,
        pageCurrent: action.page,
        objFiltered: aux,
        changePage: true,
        responseAuthenticationsList: null
      }
    }

    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_SET_TRANSFORMED_DATA: {
      return {
        ...state,
        isTransformedData: action.status
      }
    }

    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_FECTH_TRANSACTIONS_BY_CARD: {
      return {
        ...state,
        detailCardNumber: action.card,
        responseTransactionsByCard: null,
        isLoadingTransactionsByCard: true,
        pageCurrentTransactions: action.page
      }
    }
    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_RECEIVE_FECTH_TRANSACTIONS_BY_CARD: {
      return {
        ...state,
        responseTransactionsByCard: action.response,
        isLoadingTransactionsByCard: false,
        tabListTransactionsByCard: action.response.data
      }
    }

    case AUTHENTICATION_ACTIONS.AUTHENTICATION_ACTIONS_CHANGE_ORDER: {
      const aux = { ...state.objFiltered }
      if (action.direction !== 'none') {
        aux.sort = action.direction+"_"+action.field;
      } else {
        aux.sort = '';
      }
      const orderBy = {
        field: action.field,
        direction: action.direction
      }

      return {
        ...state,
        orderBy: orderBy,
        changePage: true,
        responseAuthenticationsList: null,
        objFiltered: aux
      }
    }

    default:
      return state
  }
}

export default queryauthentication;