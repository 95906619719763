import { OPERATION_ACTIONS } from "../../constants/actions"

export const getOperation = (id, card_sha, token, issuerSelected) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_GET_OPERATION,
    id,
    card_sha,
    token,
    issuerSelected
  }
}

export const receiveGetOperation = (response) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_GET_OPERATION,
    response
  }
}

export const getOperationList = (filters, token, page, issuerSelected) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS,
    filters,
    token,
    page,
    issuerSelected
  }
}

export const exportOperations = (filters, token, issuerSelected) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_EXPORT_FILE,
    filters,
    token,
    issuerSelected
  }
}

export const receiveExportOperations = (response) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_EXPORT_FILE,
    response
  }
}

export const resetExportOperations = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_RESET_EXPORT_FILE,
  }
}

export const receiveGetOperationList = (response) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_LIST_OPERATIONS,
    response
  }
}

export const nextPage = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_NEXT_PAGE
  }
}

export const backPage = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_BACK_PAGE
  }
}

export const goToPage = (filters, page, token, issuerSelected) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_GO_TO_PAGE,
    filters,
    page,
    token,
    issuerSelected
  }
}

export const resetFilters = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_RESET_FILTERS
  }
}

export const handleFilters = (id, value) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_HANDLE_FILTERS,
    id,
    value
  }
}

export const deleteFilter = (filters, token, page, issuerSelected, filterSelectedToDelete, propertyName) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_LIST_OPERATIONS_DELETE_FILTERS,
    filters,
    token,
    page,
    issuerSelected,
    filterSelectedToDelete,
    propertyName
  }
}

export const resetToFirstPage = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_RESET_TO_FIRST
  }
}

export const fetchOperationsByCard = (card, token, page, issuerSelected) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_OPERATIONS_BY_CARD,
    card,
    token,
    page,
    issuerSelected
  }
}

export const receiveFetchOperationsByCard = (response) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_RECEIVE_FETCH_OPERATIONS_BY_CARD,
    response
  }
}

export const fetchOperationsByCardNextPage = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_NEXT_PAGE_OPERATIONS_BY_CARD
  }
}

export const fetchOperationsByCardBackPage = () => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_BACK_PAGE_OPERATIONS_BY_CARD
  }
}

export const fetchOperationsByCardGoToPage = (page) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_FETCH_GOTO_PAGE_OPERATIONS_BY_CARD,
    page: page,
  }
}

export const changeOrder = (field, direction, token, issuerSelected, page, filters) => {
  return {
    type: OPERATION_ACTIONS.OPERATION_ACTIONS_CHANGE_ORDER,
    field,
    direction,
    token,
    issuerSelected,
    page,
    filters
  }
}