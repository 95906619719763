export const LOGIN_ACTIONS = {
	LOGIN_ACTIONS_LOGIN_USER: 'LOGIN_ACTIONS_LOGIN_USER',
	LOGIN_ACTIONS_RECEIVE_AUTH_LOGIN_USER: 'LOGIN_ACTIONS_LOGIN_ACTIONS_RECEIVE_AUTH_LOGIN_USER',
	LOGIN_ACTIONS_LOGOUT: 'LOGIN_ACTIONS_LOGOUT',
	LOGIN_ACTIONS_RESET_VALUES: 'LOGIN_ACTIONS_RESET_VALUES',
	LOGIN_ACTIONS_VALIDATION_BY_CODE: 'LOGIN_ACTIONS_LOGIN_ACTIONS_VALIDATION_BY_CODE',
	LOGIN_ACTIONS_RECEIVE_VALIDATION_BY_CODE: 'LOGIN_ACTIONS_LOGIN_ACTIONS_RECEIVE_VALIDATION_BY_CODE',

  LOGIN_ACTIONS_SET_ROLE: 'LOGIN_ACTIONS_SET_ROLE',

	LOGIN_ACTIONS_LOGIN_RESET: 'LOGIN_ACTIONS_LOGIN_RESET',
	LOGIN_ACTIONS_HANDLE_USER_INFO: 'LOGIN_ACTIONS_HANDLE_USER_INFO',

	LOGIN_ACTIONS_LOGIN_VALIDATE_FIELDS: 'LOGIN_ACTIONS_LOGIN_VALIDATE_FIELDS',
	LOGIN_ACTIONS_RESET_VALIDATE_FIELDS: 'LOGIN_ACTIONS_RESET_VALIDATE_FIELDS',

	LOGIN_ACTIONS_HANDLE_CODE: 'LOGIN_ACTIONS_HANDLE_CODE',
	LOGIN_ACTIONS_SEND_CODE: 'LOGIN_ACTIONS_SEND_CODE',
	LOGIN_ACTIONS_RECEIVE_PUT_LOGIN_CODE: 'LOGIN_ACTIONS_RECEIVE_LOGIN_CODE',

	LOGIN_ACTIONS_COUNTER_ATTEMPS: 'LOGIN_ACTIONS_COUNTER_ATTEMPS',

	LOGIN_ACTIONS_SHOW_PASSWORD: 'LOGIN_ACTIONS_SHOW_PASSWORD',

	LOGIN_ACTIONS_LOGIN_RESET_DISABLED: 'LOGIN_ACTIONS_LOGIN_RESET_DISABLED',

	LOGIN_ACTIONS_RESET_VALIDATION_CHALLENGE: 'LOGIN_ACTIONS_RESET_VALIDATION_CHALLENGE',

	LOGIN_ACTIONS_HANDLE_FIELDS_FILTEREDS: 'LOGIN_ACTIONS_HANDLE_FIELDS_FILTEREDS',

	LOGIN_ACTIONS_RESET_PASSWORD: 'LOGIN_ACTIONS_RESET_PASSWORD',

	LOGIN_ACTIONS_RECEIVE_RESPONSE_RESET_PASSWORD: 'LOGIN_ACTIONS_RECEIVE_RESPONSE_RESET_PASSWORD',
	LOGIN_ACTIONS_RESET_VALUES_RECOVERY_PASSWORD: 'LOGIN_ACTIONS_RESET_VALUES_RECOVERY_PASSWORD',

  LOGIN_ACTIONS_VALIDATE_ACQUIRERS_AVAILABLE: 'LOGIN_ACTIONS_VALIDATE_ACQUIRERS_AVAILABLE',
  LOGIN_ACTIONS_VALIDATE_ISSUERS_AVAILABLE: 'LOGIN_ACTIONS_VALIDATE_ISSUERS_AVAILABLE',
  
  LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ACQUIRERS_AVAILABLE: 'LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ACQUIRERS_AVAILABLE',
  LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ISSUERS_AVAILABLE: 'LOGIN_ACTIONS_VALIDATE_RECEIVE_RESPONSE_ISSUERS_AVAILABLE',

	LOGIN_ACTIONS_RESENT_CODE: 'LOGIN_ACTIONS_RESENT_CODE',
	LOGIN_ACTIONS_RECEIVE_RESPONSE_RESENT_CODE: 'LOGIN_ACTIONS_RECEIVE_RESPONSE_RESENT_CODE',

	LOGIN_ACTIONS_RESET_VALUES_RESENT_CODE: 'LOGIN_ACTIONS_RESET_VALUES_RESENT_CODE'
}

export const HOME_ACTIONS = {
	HOME_ACTIONS_UPDATE_QUERIES: 'HOME_ACTIONS_UPDATE_QUERIES',
  HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER: 'HOME_ACTIONS_LIST_BRANDS_BY_ACQUIRER',
  HOME_ACTIONS_LIST_BRANDS_BY_ISSUER: 'HOME_ACTIONS_LIST_BRANDS_BY_ISSUER',

  HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ACQUIRER: 'HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ACQUIRER',
  HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ISSUER: 'HOME_ACTIONS_RECEIVE_RESPONSE_LIST_BRANDS_BY_ISSUER',

  HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER: 'HOME_ACTIONS_LIST_COUNTRIES_BY_ACQUIRER',
  HOME_ACTIONS_LIST_COUNTRIES_BY_ISSUER: 'HOME_ACTIONS_LIST_COUNTRIES_BY_ISSUER',

  HOME_ACTIONS_RECEIVE_RESPONSE_LIST_COUNTRIES: 'HOME_ACTIONS_RECEIVE_RESPONSE_LIST_COUNTRIES',
  
  HOME_ACTIONS_LIST_ACQUIRERS_LOADING: 'HOME_ACTIONS_LIST_ACQUIRERS_LOADING',
  HOME_ACTIONS_LIST_ISSUERS_LOADING: 'HOME_ACTIONS_LIST_ISSUERS_LOADING',

  HOME_ACTIONS_LIST_ACQUIRERS_LOADED: 'HOME_ACTIONS_LIST_ACQUIRERS_LOADED',
  HOME_ACTIONS_LIST_ISSUERS_LOADED: 'HOME_ACTIONS_LIST_ISSUERS_LOADED',

	HOME_ACTIONS_RESET_BRANDS: 'HOME_ACTIONS_RESET_BRANDS',
  HOME_ACTIONS_SELECT_ACQUIRER_DASHBOARD: 'HOME_ACTIONS_SELECT_ACQUIRER_DASHBOARD',
  HOME_ACTIONS_SELECT_ISSUER_DASHBOARD: 'HOME_ACTIONS_SELECT_ISSUER_DASHBOARD',
	HOME_ACTIONS_GET_USER_ACTIVE: 'HOME_ACTIONS_GET_USER_ACTIVE',
	HOME_ACTIONS_RECEIVE_RESPONSE_GET_USER_ACTIVE: 'HOME_ACTIONS_RECEIVE_RESPONSE_GET_USER_ACTIVE',

  HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER: 'HOME_ACTIONS_HANDLE_PICTURE_ACQUIRER',
  HOME_ACTIONS_HANDLE_PICTURE_ISSUERS: 'HOME_ACTIONS_HANDLE_PICTURE_ISSUERS',
  HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ACQUIRER: 'HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ACQUIRER',
  HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ISSUER: 'HOME_ACTIONS_RECEIVE_RESPONSE_HANDLE_PICTURE_ISSUER'
}

export const MERCHANTS_ACTIONS = {
	MERCHANTS_ACTIONS_UPDATE_BODY_NEW_MERCHANT: 'MERCHANTS_ACTIONS_UPDATE_BODY_NEW_MERCHANT',
	MERCHANTS_ACTIONS_DELETE_FILTERS: 'MERCHANTS_ACTIONS_DELETE_FILTERS',
	MERCHANTS_ACTIONS_SHOW_FORM_REGISTER: 'MERCHANTS_ACTIONS_SHOW_FORM_REGISTER',
	MERCHANTS_ACTIONS_SENT_FILTER_LIST: 'MERCHANTS_ACTIONS_SENT_FILTER_LIST',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_FILTER_LIST: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_FILTER_LIST',
	MERCHANTS_ACTIONS_HANDLE_VALUES_FILTERS: 'MERCHANTS_ACTIONS_HANDLE_VALUES_FILTERS',
	MERCHANTS_ACTIONS_REGISTER_MERCHANT: 'MERCHANTS_ACTIONS_REGISTER_MERCHANT',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_REGISTER_MERCHANT: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_REGISTER_MERCHANT',
	MERCHANTS_ACTIONS_HANDLE_FIELDS_REGISTER_MERCHANT: 'MERCHANTS_ACTIONS_HANDLE_FIELDS_REGISTER_MERCHANT',
	MERCHANTS_ACTIONS_HANDLE_ECOMMERCE_CODE: 'MERCHANTS_ACTIONS_HANDLE_ECOMMERCE_CODE',
	MERCHANTS_ACTIONS_UPDATE_BRAND_SELECTED: 'MERCHANTS_ACTIONS_UPDATE_BRAND_SELECTED',
	MERCHANTS_ACTIONS_UPDATE_CURRENCY_SELECTED: 'MERCHANTS_ACTIONS_UPDATE_CURRENCY_SELECTED',

	MERCHANTS_ACTIONS_HANDLE_FIELDS_UPLOAD_MERCHANT: 'MERCHANTS_ACTIONS_HANDLE_FIELDS_UPLOAD_MERCHANT',

	MERCHANTS_ACTIONS_GET_MERCHANT_INFO: 'MERCHANTS_ACTIONS_GET_MERCHANT_INFO',
	MERCHANTS_ACTIONS_UPDATE_CURRENCIES_EDIT_MERCHANT: 'MERCHANTS_ACTIONS_UPDATE_CURRENCIES_EDIT_MERCHANT',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPDATE_CURRENCIES_EDIT_MERCHANT: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPDATE_CURRENCIES_EDIT_MERCHANT',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_MERCHANT_INFO: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_MERCHANT_INFO',

	MERCHANTS_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS: 'MERCHANTS_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS',

	MERCHANTS_ACTIONS_GENERATE_BRANDS_INITIAL: 'MERCHANTS_ACTIONS_GENERATE_BRANDS_INITIAL',
	MERCHANTS_ACTIONS_UPDATE_NEW_REGISTER_FIELDS: 'MERCHANTS_ACTIONS_UPDATE_NEW_REGISTER_FIELDS',

	MERCHANTS_ACTIONS_HANDLE_FILE: 'MERCHANTS_ACTIONS_HANDLE_FILE',

	MERCHANTS_ACTIONS_UPLOAD_FILE: 'MERCHANTS_ACTIONS_UPLOAD_FILE',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE',

	MERCHANTS_ACTIONS_INCREASE_PERCENT: 'INCREASE_PERCENT',

	MERCHANTS_ACTIONS_EDIT_UPDATE_ALL_BODY: 'MERCHANTS_ACTIONS_EDIT_UPDATE_ALL_BODY',

	MERCHANTS_ACTIONS_PUT_BATCH: 'MERCHANTS_ACTIONS_PUT_BATCH',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_PUT_BATCH: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_PUT_BATCH',

	MERCHANTS_ACTIONS_EDIT_CURRENCY_SELECTED: 'MERCHANTS_ACTIONS_EDIT_CURRENCY_SELECTED',
	MERCHANTS_ACTIONS_EDIT_BRAND_SELECTED: 'MERCHANTS_ACTIONS_EDIT_BRAND_SELECTED',
	MERCHANTS_ACTIONS_EDIT_HANDLE_ECOMMERCE_CODE: 'MERCHANTS_ACTIONS_EDIT_HANDLE_ECOMMERCE_CODE',

	MERCHANTS_ACTIONS_RESET_EDIT_MERCHANT_VALUES: 'MERCHANTS_ACTIONS_RESET_EDIT_MERCHANT_VALUES',

	MERCHANTS_ACTIONS_BRANDS_CURRENCIES_MERCHANT_EDIT: 'MERCHANTS_ACTIONS_BRANDS_CURRENCIES_MERCHANT_EDIT',

	MERCHANTS_ACTIONS_EDIT_MERCHANT: 'MERCHANTS_ACTIONS_EDIT_MERCHANT',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_EDIT_MERCHANT: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_EDIT_MERCHANT',
	MERCHANTS_ACTIONS_CLEAN_ALL_FILTERS: 'MERCHANTS_ACTIONS_CLEAN_ALL_FILTERS',

	MERCHANTS_ACTIONS_SHOW_MODAL_REGISTER: 'MERCHANTS_ACTIONS_SHOW_MODAL_REGISTER',

	MERCHANTS_ACTIONS_RESET_VALUES_REGISTER_MERCHANT: 'MERCHANTS_ACTIONS_RESET_VALUES_REGISTER_MERCHANT',
	MERCHANTS_ACTIONS_NEXT_PAGE: 'MERCHANTS_ACTIONS_NEXT_PAGE',
	MERCHANTS_ACTIONS_BACK_PAGE: 'MERCHANTS_ACTIONS_BACK_PAGE',
	MERCHANTS_ACTIONS_UPDATE_FILTERS: 'MERCHANTS_ACTIONS_UPDATE_FILTERS',
	//BULK LOAD
	MERCHANTS_ACTIONS_RESET_CORRECT_FORMAT: 'MERCHANTS_ACTIONS_RESET_CORRECT_FORMAT',
	MERCHANTS_ACTIONS_RESET_VALUES_BULK_LOAD: '	MERCHANTS_ACTIONS_RESET_VALUES_BULK_LOAD',
	MERCHANTS_ACTIONS_RESET_VALUES: 'MERCHANTS_ACTIONS_RESET_VALUES',
	MERCHANTS_ACTIONS_GET_URL_UPLOAD_FILE: 'MERCHANTS_ACTIONS_GET_URL_UPLOAD_FILE',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE',
	MERCHANTS_ACTIONS_SENT_FILE_TO_SERVER: 'MERCHANTS_ACTIONS_SENT_FILE_TO_SERVER',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_FILE_TO_SERVER: 'MERCHANTS_ACTIONS_SENT_FILE_TO_SERVER',
	MERCHANTS_ACTIONS_SENT_ALL_FILTERS: 'MERCHANTS_ACTIONS_SENT_ALL_FILTERS',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_ALL_FILTERS: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_SENT_ALL_FILTERS',
	MERCHANTS_ACTIONS_UPDATE_PERCENT_LOADED: 'MERCHANTS_ACTIONS_UPDATE_PERCENT_LOADED',
	MERCHANTS_ACTIONS_DISABLED_BUTTON_UPLOAD: 'MERCHANTS_ACTIONS_DISABLED_BUTTON_UPLOAD',
	MERCHANTS_ACTIONS_GENERATE_URL_DOWNLOAD_FILE: 'MERCHANTS_ACTIONS_GENERATE_URL_DOWNLOAD_FILE',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE',
	MERCHANTS_ACTIONS_DOWNLOAD_FILE: 'MERCHANTS_ACTIONS_DOWNLOAD_FILE',
	MERCHANTS_ACTIONS_CLOSE_MODAL_EDIT_MERCHANT: 'MERCHANTS_ACTIONS_CLOSE_MODAL_EDIT_MERCHANT',
	MERCHANTS_ACTIONS_RECEIVE_RESPONSE_DOWNLOAD_FILE: 'MERCHANTS_ACTIONS_RECEIVE_RESPONSE_DOWNLOAD_FILE',
	MERCHANTS_ACTIONS_RESET_VALUES_MERCHANTS_FILTERS: 'MERCHANTS_ACTIONS_RESET_VALUES_MERCHANTS_FILTERS'
}

export const TRANSACTION_ACTIONS = {
	TRANSACTION_ACTIONS_SENT_FILTERS_LIST_TRANSACTIONS: 'TRANSACTION_ACTIONS_SENT_FILTERS_LIST_TRANSACTIONS',
	TRANSACTION_ACTIONS_RECEIVE_RESPONSE_SENT_FILTERS_LIST_TRANSACTIONS: 'TRANSACTION_ACTIONS_RECEIVE_RESPONSE_SENT_FILTERS_LIST_TRANSACTIONS',

	TRANSACTION_ACTIONS_GET_INFO: 'TRANSACTION_ACTIONS_GET_INFO',
	TRANSACTION_ACTIONS_RECEIVE_RESPONSE_GET_INFO: 'TRANSACTION_ACTIONS_RECEIVE_RESPONSE_GET_INFO',
	
	TRANSACTION_ACTIONS_NEXT_PAGE: 'TRANSACTION_ACTIONS_NEXT_PAGE',
	TRANSACTION_ACTIONS_BACK_PAGE: 'TRANSACTION_ACTIONS_BACK_PAGE',

	TRANSACTION_ACTIONS_GO_TO_PAGE: 'TRANSACTION_ACTIONS_GO_TO_PAGE',

	TRANSACTION_ACTIONS_FILTERS_RESET: 'TRANSACTION_ACTIONS_FILTERS_RESET',
	TRANSACTION_ACTIONS_HANDLE_FIELDS_FILTEREDS: 'TRANSACTION_ACTIONS_HANDLE_FIELDS_FILTEREDS',

	TRANSACTION_ACTIONS_DELETE_FILTERS: 'TRANSACTION_ACTIONS_DELETE_FILTERS'
}

export const REGISTER_ACTIONS = {
	REGISTER_ACTIONS_REGISTER_USER: 'REGISTER_ACTIONS_REGISTER_USER',
	REGISTER_ACTIONS_RECEIVE_RESPONSE: 'REGISTER_ACTIONS_RECEIVE_RESPONSE',
	REGISTER_ACTIONS_GET_EMAIL_BY_CODE: 'REGISTER_ACTIONS_GET_EMAIL_BY_CODE',
	REGISTER_ACTIONS_VALIDATION_USER_IS_NEW: 'REGISTER_ACTIONS_VALIDATION_USER_IS_NEW',
	REGISTER_ACTIONS_RECEIVE_RESPONSE_EMAIL_BY_CODE: 'REGISTER_ACTIONS_RECEIVE_RESPONSE_EMAIL_BY_CODE',
	REGISTER_ACTIONS_HANDLE_USER_INFO: 'REGISTER_ACTIONS_HANDLE_USER_INFO',
	REGISTER_ACTIONS_RESET: 'REGISTER_ACTIONS_RESET'
}

export const RESET_PASSWORD = {
	RESET_PASSWORD_USER: 'RESET_PASSWORD_USER',
	RESET_PASSWORD_RECEIVE_RESPONSE: 'RESET_PASSWORD_RECEIVE_RESPONSE',
	RESET_PASSWORD_GET_EMAIL_BY_CODE: 'RESET_PASSWORD_GET_EMAIL_BY_CODE',
	RESET_PASSWORD_RESET_VALUES: 'RESET_PASSWORD_RESET_VALUES',
	RESET_PASSWORD_RECEIVE_RESPONSE_EMAIL_BY_CODE: 'RESET_PASSWORD_RECEIVE_RESPONSE_EMAIL_BY_CODE',
	RESET_PASSWORD_HANDLE_USER_INFO: 'RESET_PASSWORD_HANDLE_USER_INFO'
}

export const USERS_ACTIONS = {
	USERS_ACTIONS_HANDLE_VALUES: 'USERS_ACTIONS_HANDLE_VALUES',

	USERS_ACTIONS_LIST_USERS: 'USERS_ACTIONS_LIST_USERS',
	USERS_ACTIONS_RECEIVE_RESPONSE_LIST_USERS: 'USERS_ACTIONS_RECEIVE_RESPONSE_LIST_USERS',

	USERS_ACTIONS_GET_INFO: 'USERS_ACTIONS_GET_INFO',
	USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFO: 'USERS_ACTIONS_RECEIVE_RESPONSE_GET_INFO',

	USERS_ACTIONS_SENT_INVITATION: 'USERS_ACTIONS_SENT_INVITATION',
	USERS_ACTIONS_RECEIVE_RESPONSE_SENT_INVITATION: 'USERS_ACTIONS_RECEIVE_RESPONSE_SENT_INVITATION',

	USERS_ACTIONS_HANDLE_USER_INVITED_FIELDS: 'USERS_ACTIONS_HANDLE_USER_INVITED_FIELDS',

	USERS_ACTIONS_UPDATE_LIST_USERS: 'USERS_ACTIONS_UPDATE_LIST_USERS',

	USERS_ACTIONS_RESET_VALUES: 'USERS_ACTIONS_RESET_VALUES',

	USERS_ACTIONS_NEXT_PAGE: 'USERS_ACTIONS_NEXT_PAGE',
	USERS_ACTIONS_BACK_PAGE: 'USERS_ACTIONS_BACK_PAGE',

  USERS_ACTIONS_GO_TO_PAGE: 'USERS_ACTIONS_GO_TO_PAGE',
	
	USERS_ACTIONS_NEXT_PAGE_DETAIL: 'USERS_ACTIONS_NEXT_PAGE_DETAIL',
	USERS_ACTIONS_BACK_PAGE_DETAIL: 'USERS_ACTIONS_BACK_PAGE_DETAIL',
	
	USERS_ACTIONS_DASHBOARD_NEXT_PAGE: 'USERS_ACTIONS_DASHBOARD_NEXT_PAGE',
	USERS_ACTIONS_DASHBOARD_BACK_PAGE: 'USERS_ACTIONS_DASHBOARD_BACK_PAGE',

	USERS_ACTIONS_GET_ASSOCIATE_DETAIL: 'USERS_ACTIONS_GET_ASSOCIATE_DETAIL',
	USERS_ACTIONS_RECEIVE_RESPONSE_GET_ASSOCIATE_DETAIL: 'USERS_ACTIONS_RECEIVE_RESPONSE_GET_ASSOCIATE_DETAIL',

	USER_ACTIONS_GET_INFO_PROFILE: 'USER_ACTIONS_GET_INFO_PROFILE',
	USER_ACTIONS_RECEIVE_RESPONSE_GET_INFO_PROFILE: 'USER_ACTIONS_RECEIVE_RESPONSE_GET_INFO_PROFILE',

	USERS_ACTIONS_DELETE_USER: 'USERS_ACTIONS_DELETE_USER',
	USERS_ACTIONS_RECEIVE_RESPONSE_DELETE_USER: 'USERS_ACTIONS_RECEIVE_RESPONSE_DELETE_USER',

	USERS_ACTIONS_UPDATE_USER_INFO: 'USERS_ACTIONS_UPDATE_USER_INFO',
	USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO: 'USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_USER_INFO',
	
	USERS_ACTIONS_RESENT_INVITATION: 'USERS_ACTIONS_RESENT_INVITATION',
	USERS_ACTIONS_RECEIVE_RESPONSE_RESENT_INVITATION: 'USERS_ACTIONS_RECEIVE_RESPONSE_RESENT_INVITATION',

	USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE: 'USERS_ACTIONS_UPDATE_IMAGE_PICTURE_USER_ACTIVE',
	USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_IMAGE_PICTURE_USER_ACTIVE: 'USERS_ACTIONS_RECEIVE_RESPONSE_UPDATE_IMAGE_PICTURE_USER_ACTIVE',

	USERS_ACTIONS_CANCEL_INVITATION: 'USERS_ACTIONS_CANCEL_INVITATION',
	USERS_ACTIONS_RECEIVE_RESPONSE_CANCEL_INVITATION: 'USERS_ACTIONS_RECEIVE_RESPONSE_CANCEL_INVITATION',
	
	USERS_ACTIONS_RESET_VALUES_UPLOAD_IMAGE: 'USERS_ACTIONS_RESET_VALUES_UPLOAD_IMAGE',

	USERS_ACTIONS_HANDLE_VALUES_UPDATE_INFO_USER: 'USERS_ACTIONS_HANDLE_VALUES_UPDATE_INFO_USER',
	USERS_ACTIONS_UPDATE_USER_ACTIVE: 'USERS_ACTIONS_UPDATE_USER_ACTIVE',

  USERS_ACTIONS_PROFILE_UPDATE_DATA: 'USERS_ACTIONS_PROFILE_UPDATE_DATA',
  USERS_ACTIONS_PROFILE_UPDATE_DATA_HANDLE: 'USERS_ACTIONS_PROFILE_UPDATE_DATA_HANDLE',
  USERS_ACTIONS_PROFILE_UPDATE_DATA_RECEIVE: 'USERS_ACTIONS_PROFILE_UPDATE_DATA_RECEIVE',
  USERS_ACTIONS_PROFILE_UPDATE_DATA_RESET_VALUES: 'USERS_ACTIONS_PROFILE_UPDATE_DATA_RESET_VALUES',
  USERS_ACTIONS_PROFILE_UPDATE_DATA_RESET_VALUES_FORM: 'USERS_ACTIONS_PROFILE_UPDATE_DATA_RESET_VALUES_FORM',

  USERS_ACTIONS_PROFILE_UPDATE_PASSWORD: 'USERS_ACTIONS_PROFILE_UPDATE_PASSWORD',
  USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_HANDLE: 'USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_HANDLE',
  USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RECEIVE: 'USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RECEIVE',
  USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RESET_VALUES: 'USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RESET_VALUES',
  USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RESET_VALUES_FORM: 'USERS_ACTIONS_PROFILE_UPDATE_PASSWORD_RESET_VALUES_FORM',

  USERS_ACTIONS_SET_PROFILE_DATA: 'USERS_ACTIONS_SET_PROFILE_DATA',
  USERS_ACTIONS_RESET_UPDATE_DATA_VALUES: 'USERS_ACTIONS_RESET_UPDATE_DATA_VALUES',
  USERS_ACTIONS_RESET_UPDATE_PASSWORD_VALUES: 'USERS_ACTIONS_RESET_UPDATE_PASSWORD_VALUES',
  USERS_ACTIONS_DELETE_FILTERS: 'USERS_ACTIONS_DELETE_FILTERS',

  USERS_ACTIONS_CHANGE_ORDER: 'USERS_ACTIONS_CHANGE_ORDER',
}

export const AUTHENTICATION_ACTIONS = {
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS',
  AUTHENTICATION_ACTIONS_RECEIVE_LIST_TRANSACTIONS: 'AUTHENTICATION_ACTIONS_RECEIVE_LIST_TRANSACTIONS',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_NEXT_PAGE: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_NEXT_PAGE',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_BACK_PAGE: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_BACK_PAGE',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_GO_TO_PAGE: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_GO_TO_PAGE',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_FILTERS: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_FILTERS',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_HANDLE_FILTERS: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_HANDLE_FILTERS',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_DELETE_FILTERS: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_DELETE_FILTERS',
  AUTHENTICATION_ACTIONS_GET_TRANSACTION: 'AUTHENTICATION_ACTIONS_GET_TRANSACTION',
  AUTHENTICATION_ACTIONS_RECEIVE_GET_TRANSACTION: 'AUTHENTICATION_ACTIONS_RECEIVE_GET_TRANSACTION',
  AUTHENTICATION_ACTIONS_SET_TRANSFORMED_DATA: 'AUTHENTICATION_ACTIONS_SET_TRANSFORMED_DATA',
  AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_PAGE: 'AUTHENTICATION_ACTIONS_LIST_TRANSACTIONS_RESET_PAGE',
  AUTHENTICATION_ACTIONS_FECTH_TRANSACTIONS_BY_CARD: 'AUTHENTICATION_ACTIONS_FECTH_TRANSACTIONS_BY_CARD',
  AUTHENTICATION_ACTIONS_RECEIVE_FECTH_TRANSACTIONS_BY_CARD: 'AUTHENTICATION_ACTIONS_RECEIVE_FECTH_TRANSACTIONS_BY_CARD',
  AUTHENTICATION_ACTIONS_FETCH_NEXT_PAGE_TRANSACTIONS_BY_CARD: 'AUTHENTICATION_ACTIONS_FETCH_NEXT_PAGE_TRANSACTIONS_BY_CARD',
  AUTHENTICATION_ACTIONS_FETCH_BACK_PAGE_TRANSACTIONS_BY_CARD: 'AUTHENTICATION_ACTIONS_FETCH_BACK_PAGE_TRANSACTIONS_BY_CARD',
  AUTHENTICATION_ACTIONS_FETCH_GOTO_PAGE_TRANSACTIONS_BY_CARD: 'AUTHENTICATION_ACTIONS_FETCH_GOTO_PAGE_TRANSACTIONS_BY_CARD',
  AUTHENTICATION_ACTIONS_EXPORT_FILE: 'AUTHENTICATION_ACTIONS_EXPORT_FILE',
  AUTHENTICATION_ACTIONS_RECEIVE_EXPORT_FILE: 'AUTHENTICATION_ACTIONS_RECEIVE_EXPORT_FILE',
  AUTHENTICATION_ACTIONS_RESET_EXPORT_FILE: 'AUTHENTICATION_ACTIONS_RESET_EXPORT_FILE',
  AUTHENTICATION_ACTIONS_CHANGE_ORDER: 'AUTHENTICATION_ACTIONS_CHANGE_ORDER',
}

export const OPERATION_ACTIONS = {
  OPERATION_ACTIONS_LIST_OPERATIONS: 'OPERATION_ACTIONS_LIST_OPERATIONS',
  OPERATION_ACTIONS_RECEIVE_LIST_OPERATIONS: 'OPERATION_ACTIONS_RECEIVE_LIST_OPERATIONS',
  OPERATION_ACTIONS_LIST_OPERATIONS_NEXT_PAGE: 'OPERATION_ACTIONS_LIST_OPERATIONS_NEXT_PAGE',
  OPERATION_ACTIONS_LIST_OPERATIONS_BACK_PAGE: 'OPERATION_ACTIONS_LIST_OPERATIONS_BACK_PAGE',
  OPERATION_ACTIONS_LIST_OPERATIONS_GO_TO_PAGE: 'OPERATION_ACTIONS_LIST_OPERATIONS_GO_TO_PAGE',
  OPERATION_ACTIONS_LIST_OPERATIONS_RESET_FILTERS: 'OPERATION_ACTIONS_LIST_OPERATIONS_RESET_FILTERS',
  OPERATION_ACTIONS_LIST_OPERATIONS_HANDLE_FILTERS: 'OPERATION_ACTIONS_LIST_OPERATIONS_HANDLE_FILTERS',
  OPERATION_ACTIONS_LIST_OPERATIONS_DELETE_FILTERS: 'OPERATION_ACTIONS_LIST_OPERATIONS_DELETE_FILTERS',
  OPERATION_ACTIONS_GET_OPERATION: 'OPERATION_ACTIONS_GET_OPERATION',
  OPERATION_ACTIONS_RECEIVE_GET_OPERATION: 'OPERATION_ACTIONS_RECEIVE_GET_OPERATION',
  OPERATION_ACTIONS_RESET_TO_FIRST: 'OPERATION_ACTIONS_RESET_TO_FIRST',
  OPERATION_ACTIONS_FETCH_OPERATIONS_BY_CARD: 'OPERATION_ACTIONS_FETCH_OPERATIONS_BY_CARD',
  OPERATION_ACTIONS_RECEIVE_FETCH_OPERATIONS_BY_CARD: 'OPERATION_ACTIONS_RECEIVE_FETCH_OPERATIONS_BY_CARD',
  OPERATION_ACTIONS_FETCH_NEXT_PAGE_OPERATIONS_BY_CARD: 'OPERATION_ACTIONS_FETCH_NEXT_PAGE_OPERATIONS_BY_CARD',
  OPERATION_ACTIONS_FETCH_BACK_PAGE_OPERATIONS_BY_CARD: 'OPERATION_ACTIONS_FETCH_BACK_PAGE_OPERATIONS_BY_CARD',
  OPERATION_ACTIONS_FETCH_GOTO_PAGE_OPERATIONS_BY_CARD: 'OPERATION_ACTIONS_FETCH_GOTO_PAGE_OPERATIONS_BY_CARD',
  OPERATION_ACTIONS_EXPORT_FILE: 'OPERATION_ACTIONS_EXPORT_FILE',
  OPERATION_ACTIONS_RECEIVE_EXPORT_FILE: 'OPERATION_ACTIONS_RECEIVE_EXPORT_FILE',
  OPERATION_ACTIONS_RESET_EXPORT_FILE: 'OPERATION_ACTIONS_RESET_EXPORT_FILE',
  OPERATION_ACTIONS_CHANGE_ORDER: 'OPERATION_ACTIONS_CHANGE_ORDER',
}

export const WHITELIST_ACTIONS = {
	WHITELIST_ACTIONS_UPDATE_COMMERCE:'WHITELIST_ACTIONS_UPDATE_COMMERCE',
	WHITELIST_ACTIONS_RECEIVE_UPDATE_COMMERCE:'WHITELIST_ACTIONS_RECEIVE_UPDATE_COMMERCE',
	WHITELIST_ACTIONS_RESET_UPDATE_COMMERCE:'WHITELIST_ACTIONS_RESET_UPDATE_COMMERCE',
	WHITELIST_ACTIONS_LIST_WHITELIST: 'WHITELIST_ACTIONS_LIST_WHITELIST',
	WHITELIST_ACTIONS_RECEIVE_LIST_WHITELIST: 'WHITELIST_ACTIONS_RECEIVE_LIST_WHITELIST',
	WHITELIST_ACTIONS_LIST_WHITELIST_NEXT_PAGE: 'WHITELIST_ACTIONS_LIST_WHITELIST_NEXT_PAGE',
	WHITELIST_ACTIONS_LIST_WHITELIST_BACK_PAGE: 'WHITELIST_ACTIONS_LIST_WHITELIST_BACK_PAGE',
	WHITELIST_ACTIONS_LIST_WHITELIST_GO_TO_PAGE: 'WHITELIST_ACTIONS_LIST_WHITELIST_GO_TO_PAGE',
	WHITELIST_ACTIONS_FETCH_WHITELIST_BY_CARD: 'WHITELIST_ACTIONS_FETCH_WHITELIST_BY_CARD',
	WHITELIST_ACTIONS_RECEIVE_FETCH_WHITELIST_BY_CARD: 'WHITELIST_ACTIONS_RECEIVE_FETCH_WHITELIST_BY_CARD',
	WHITELIST_ACTIONS_CLEAN_WHITELIST_BY_CARD:'WHITELIST_ACTIONS_CLEAN_WHITELIST_BY_CARD'
}

export const CARD_ACTIONS = {
  CARD_ACTIONS_LIST_CARDS: 'CARD_ACTIONS_LIST_CARDS',
  CARD_ACTIONS_RECEIVE_LIST_CARDS: 'CARD_ACTIONS_RECEIVE_LIST_CARDS',
  CARD_ACTIONS_LIST_CARDS_NEXT_PAGE: 'CARD_ACTIONS_LIST_CARDS_NEXT_PAGE',
  CARD_ACTIONS_LIST_CARDS_BACK_PAGE: 'CARD_ACTIONS_LIST_CARDS_BACK_PAGE',
  CARD_ACTIONS_LIST_CARDS_GO_TO_PAGE: 'CARD_ACTIONS_LIST_CARDS_GO_TO_PAGE',
  CARD_ACTIONS_LIST_CARDS_RESET_FILTERS: 'CARD_ACTIONS_LIST_CARDS_RESET_FILTERS',
  CARD_ACTIONS_LIST_CARDS_HANDLE_FILTERS: 'CARD_ACTIONS_LIST_CARDS_HANDLE_FILTERS',
  CARD_ACTIONS_LIST_CARDS_DELETE_FILTERS: 'CARD_ACTIONS_LIST_CARDS_DELETE_FILTERS',
  CARD_ACTIONS_ADD_CARD: 'CARD_ACTIONS_ADD_CARD',
  CARD_ACTIONS_HANDLE_VALUES_ADD_CARD: 'CARD_ACTIONS_HANDLE_VALUES_ADD_CARD',
  CARD_ACTIONS_RECEIVE_ADD_CARD: 'CARD_ACTIONS_RECEIVE_ADD_CARD',
  CARD_ACTIONS_RESET_VALUES_ADD_CARD: 'CARD_ACTIONS_RESET_VALUES_ADD_CARD',
  FETCH_CARD_REQUEST: '[CARD_QUERY]/FETCH_CARD_REQUEST',
  FETCH_CARD_SUCCESS: '[CARD_QUERY]/FETCH_CARD_SUCCESS',
  FETCH_CARD_FAILURE: '[CARD_QUERY]/FETCH_CARD_FAILURE',
  CARD_ACTIONS_EXPORT_FILE: 'CARD_ACTIONS_EXPORT_FILE',
  CARD_ACTIONS_RECEIVE_EXPORT_FILE: 'CARD_ACTIONS_RECEIVE_EXPORT_FILE',
  CARD_ACTIONS_RESET_EXPORT_FILE: 'CARD_ACTIONS_RESET_EXPORT_FILE',
  CARD_ACTIONS_CHANGE_ORDER: 'CARD_ACTIONS_CHANGE_ORDER',
  CARD_ACTIONS_UPDATE_CARD: 'CARD_ACTIONS_UPDATE_CARD',
  CARD_ACTIONS_RECEIVE_UPDATE_CARD: 'CARD_ACTIONS_RECEIVE_UPDATE_CARD',
  CARD_ACTIONS_RESET_UPDATE_CARD: 'CARD_ACTIONS_RESET_UPDATE_CARD'
}

export const ENROLL_ACTIONS = {
  ENROLL_ACTIONS_LIST_ENROLL: 'ENROLL_ACTIONS_LIST_ENROLL',
  ENROLL_ACTIONS_RECEIVE_LIST_ENROLL: 'ENROLL_ACTIONS_RECEIVE_LIST_ENROLL',
  ENROLL_ACTIONS_LIST_ENROLL_NEXT_PAGE: 'ENROLL_ACTIONS_LIST_ENROLL_NEXT_PAGE',
  ENROLL_ACTIONS_LIST_ENROLL_BACK_PAGE: 'ENROLL_ACTIONS_LIST_ENROLL_BACK_PAGE',
  ENROLL_ACTIONS_LIST_ENROLL_GO_TO_PAGE: 'ENROLL_ACTIONS_LIST_ENROLL_GO_TO_PAGE',
  ENROLL_ACTIONS_LIST_ENROLL_RESET_FILTERS: 'ENROLL_ACTIONS_LIST_ENROLL_RESET_FILTERS',
  ENROLL_ACTIONS_LIST_ENROLL_HANDLE_FILTERS: 'ENROLL_ACTIONS_LIST_ENROLL_HANDLE_FILTERS',
  ENROLL_ACTIONS_LIST_ENROLL_DELETE_FILTERS: 'ENROLL_ACTIONS_LIST_ENROLL_DELETE_FILTERS',
  ENROLL_ACTIONS_LOAD_FILE: 'ENROLL_ACTIONS_LOAD_FILE',
  ENROLL_ACTIONS_RESPONSE_LOAD_FILE: 'ENROLL_ACTIONS_RESPONSE_LOAD_FILE',
  ENROLL_ACTIONS_RESET_VALUES_ENROLL:  'ENROLL_ACTIONS_RESET_VALUES_ENROLL',
  ENROLL_ACTIONS_UPLOAD_FILE: 'ENROLL_ACTIONS_UPLOAD_FILE',
  ENROLL_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE: 'ENROLL_ACTIONS_RECEIVE_RESPONSE_UPLOAD_FILE',
  ENROLL_ACTIONS_INCREASE_PERCENT: 'ENROLL_ACTIONS_INCREASE_PERCENT',
  ENROLL_ACTIONS_GET_URL_UPLOAD_FILE: 'ENROLL_ACTIONS_GET_URL_UPLOAD_FILE',
  ENROLL_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE: 'ENROLL_ACTIONS_RECEIVE_RESPONSE_GET_URL_UPLOAD_FILE',
  ENROLL_ACTIONS_SENT_FILE_TO_SERVER: 'ENROLL_ACTIONS_SENT_FILE_TO_SERVER',
  ENROLL_ACTIONS_RECEIVE_RESPONSE_SENT_FILE_TO_SERVER: 'ENROLL_ACTIONS_RECEIVE_RESPONSE_SENT_FILE_TO_SERVER',
  ENROLL_ACTIONS_UPDATE_PERCENT_LOADED: 'ENROLL_ACTIONS_UPDATE_PERCENT_LOADED',
  ENROLL_ACTIONS_DISABLED_BUTTON_UPLOAD: 'ENROLL_ACTIONS_DISABLED_BUTTON_UPLOAD',
  ENROLL_ACTIONS_GENERATE_URL_DOWNLOAD_FILE: 'ENROLL_ACTIONS_GENERATE_URL_DOWNLOAD_FILE',
  ENROLL_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE: 'ENROLL_ACTIONS_RECEIVE_RESPONSE_GENERATE_URL_DOWNLOAD_FILE',
  ENROLL_ACTIONS_DOWNLOAD_FILE: 'ENROLL_ACTIONS_DOWNLOAD_FILE',
  ENROLL_ACTIONS_RECEIVE_RESPONSE_DOWNLOAD_FILE: 'ENROLL_ACTIONS_RECEIVE_RESPONSE_DOWNLOAD_FILE',
  ENROLL_ACTIONS_ENROLL_DELETE_FILTERS: 'ENROLL_ACTIONS_ENROLL_DELETE_FILTERS',
  ENROLL_ACTIONS_RESET_CORRECT_FORMAT: 'ENROLL_ACTIONS_RESET_CORRECT_FORMAT',
  ENROLL_ACTIONS_SENT_ENROLL_FILTERS: 'ENROLL_ACTIONS_SENT_ENROLL_FILTERS',
  ENROLL_ACTIONS_RECEIVE_RESPONSE_ENROLL_FILTERS: 'ENROLL_ACTIONS_RECEIVE_RESPONSE_ENROLL_FILTERS',
  ENROLL_ACTIONS_CLEAN_ENROLL_FILTERS: 'ENROLL_ACTIONS_CLEAN_ENROLL_FILTERS',
  ENROLL_ACTIONS_HANDLE_FILE: 'ENROLL_ACTIONS_HANDLE_FILE',
  ENROLL_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS: 'ENROLL_ACTIONS_HANDLE_VALUES_BULK_LOAD_FILTERS',
  ENROLL_ACTIONS_CHANGE_ORDER: 'ENROLL_ACTIONS_CHANGE_ORDER',
}

export const STATS_ACTIONS = {
  STATS_ACTIONS_FECTH_STATS: 'STATS_ACTIONS_FECTH_STATS',
  STATS_ACTIONS_RECEIVE_FECTH_STATS: 'STATS_ACTIONS_RECEIVE_FECTH_STATS',
  STATS_ACTIONS_RESET_VALUES_STATS: 'STATS_ACTIONS_RESET_VALUES_STATS',
}

// ACTIONS SAGA
export const SAGA_GET_REQUEST_API = 'ACTION_SAGA_GET_REQUEST_API';
export const SAGA_RECEIVE_DATA = 'ACTION_SAGA_RECEIVE_DATA';

export const SAGA_GET_COMMENTS_BY_ID = 'ACTION_SAGA_GET_COMMENTS_BY_ID';
export const SAGA_GET_RECEIVE_COMMENTS_BY_ID = 'ACTION_SAGA_GET_RECEIVE_COMMENTS_BY_ID';

