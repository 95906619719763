import { STATS_ACTIONS } from "../../constants/actions";
import { defaultValuesStats } from "../../constants/constants";

let defaultState = defaultValuesStats();

const stats = (state = defaultState, action) => {
  switch (action.type) {

    case STATS_ACTIONS.STATS_ACTIONS_FECTH_STATS: {
      return {
        ...state,
        responseStatistics: null,
        isLoadingStatistics: true,
        listStatistics: null,
        dateResponseStatistics:null
      }
    }
    case STATS_ACTIONS.STATS_ACTIONS_RECEIVE_FECTH_STATS: {
      return {
        ...state,
        responseStatistics: action.response,
        isLoadingStatistics: false,
        listStatistics: action.response.results.data_set,
        listStatisticsTotal: action.response.results.data_set.length,
        dateResponseStatistics:action.response.meta.date
      }
    }

    case STATS_ACTIONS.STATS_ACTIONS_RESET_VALUES_STATS: {
      return {
        ...state,
        responseStatistics: null,
        isLoadingStatistics: false,
        listStatistics: null,
        listStatisticsTotal: null,
        dateResponseStatistics:null
      }
    }

    default:
      return state
  }
}

export default stats;