import i18n from "../i18n";

export const ROUTE_GET = 'https://jsonplaceholder.typicode.com/posts/1/comments';

export const defaultValuesAuth = () =>({
  isReesent: false,
  wentCodeLoaded: false,
  responseResentCode: null,
  listIssuers: null,
  listIssuersLoading: false,
  listIssuersLoaded: false,
  onlyOneIssuer: false,
  responseGetAuthUserInfo: {},
  responseListIssuers: null,
  userEmailLoaded: false,
  user: {
      email: '',
      password: ''
  },
	userResponseAuth: {},
	userResponseByCode: null,
  userPermissions: null,
	responseSendCodeChallenge: null,
	isValidateCode: false,
	isLogged: false,
  isLoggedByCode: false,
  isLoading: false,
  isLoadingValidationCode: false,
	data: [],
	commentsById: [],
	validateFields: {
		email: false,
		password: false
	},
	userCode: null,
	attemp: 0,
	attempsTotal: 0,
  showPass: false,
  isDisabled: false,
  objFiltered: {
    fromDateQuery: '',
    toDateQuery: '',
    authResultQuery: '',
    nameQuery: '',
    codeQuery: '',
    numberOrderQuery: '',
    brandQuery: '',
    inclearTypeQuery: '',
    maskTypeQuery: '',
    cardNumberQuery: '',
    binQuery: '',
    currencyQuery: '',
    eciQuery: ''
  },
  mailReset: '',
  responseResetPassword: {},
  resetLoaded: false,
  resetValided: false
})

export const defaultValuesHome = () => ({
  responseIssuerImageUpdated: null,
  changeImageIsCompleted: false,
  issuerSelected: null,
  userActive: null,
  userActiveIsLoaded: false,
  listQueries: [],
  isLoaded: false,
  responseListBrands: {},
  isLoadedBrands: false,
  isLoadedCountries: false,
  brands: [],
  currencies: [],
  countries: [],
  protocols: [],
  listIssuersIsLoaded: false,
  listIssuersIsLoading: false,
  listAllIssuers: [],
  changingIssuerPicture: false,
})

export const defaultValuesQuery = () => ({
  filtersSpanish: [
    {
      name: i18n.t('Fecha de inicio'),
      state: false,
      propertyName: 'fromDateQuery'
    },
    {
      name: i18n.t('Fecha de fin'),
      state: false,
      propertyName: 'toDateQuery'
    },
    {
      name: i18n.t('Resultado de autenticación'),
      state: false,
      propertyName: 'authResultQuery'
    },
    {
      name: i18n.t('Tipo de autenticación'),
      state: false,
      propertyName: 'authTypeQuery'
    },
    {
      name: i18n.t('Nombre de comercio'),
      state: false,
      propertyName: 'nameQuery'
    },
    {
      name: i18n.t('Código de comercio'),
      state: false,
      propertyName: 'codeQuery'
    },
    {
      name: i18n.t('Número de pedido'),
      state: false,
      propertyName: 'numberOrderQuery'
    },
    {
      name: i18n.t('Número de tarjeta'),
      state: false,
      propertyName: 'cardNumberQuery'
    },
    {
      name: i18n.t('Marca'),
      state: false,
      propertyName: 'brandQuery'
    },
    {
      name: i18n.t('Enmascarado'),
      state: false,
      propertyName: 'maskTypeQuery'
    },
    {
      name: i18n.t('En claro'),
      state: false,
      propertyName: 'inclearTypeQuery'
    },
    {
      name: i18n.t('BIN'),
      state: false,
      propertyName: 'binQuery'
    },
    {
      name: i18n.t('LAST PAN'),
      state: false,
      propertyName: 'lastPanQuery'
    },
    {
      name: i18n.t('Moneda'),
      state: false,
      propertyName: 'currencyQuery'
    },
    {
      name: i18n.t('ECI'),
      state: false,
      propertyName: 'eciQuery'
    },
    {
      name: i18n.t('Canal de compra'),
      state: false,
      propertyName: 'channel'
    },
    {
      name: i18n.t('Estado de Tarjeta'),
      state: false,
      propertyName: 'statusCard'
    },
    {
      name: i18n.t('Monto Min'),
      state: false,
      propertyName: 'amountMin'
    },
    {
      name: i18n.t('Monto Max'),
      state: false,
      propertyName: 'amountMax'
    }
  ],
  isSearching: false,
  transactionsList: null,
  responseTransactionsList: null,
  isLoadedTransactions: false,
  responseTransactionInfo: null,
  isLoadedGetInfoTransaction: false,
  pageCurrent: 1,
  fieldsFiltereds: {},
  changePage: false,
  searchingFilters: false,
  objFiltered: {
    fromDateQuery: '',
    toDateQuery: '',
    authResultQuery: '',
    nameQuery: '',
    codeQuery: '',
    numberOrderQuery: '',
    brandQuery: '',
    inclearTypeQuery: '',
    maskTypeQuery: 'Enmascarada',
    cardNumberQuery: '',
    binQuery: '',
    currencyQuery: '',
    eciQuery: '',
    lastPanQuery: ''
  }
})

export const defaultValuesAuthentication = () => {
  return {
    filtersSpanish: [
      {
        name: i18n.t('Fecha de inicio'),
        state: false,
        propertyName: 'fromDateAuthentication'
      },
      {
        name: i18n.t('Fecha de fin'),
        state: false,
        propertyName: 'toDateAuthentication'
      },
      {
        name: i18n.t('Resultado de autenticación'),
        state: false,
        propertyName: 'authResultAuthentication'
      },
      {
        name: i18n.t('Nombre de comercio'),
        state: false,
        propertyName: 'nameAuthentication'
      },
      {
        name: i18n.t('Código de comercio'),
        state: false,
        propertyName: 'codeAuthentication'
      },
      {
        name: i18n.t('Número de tarjeta'),
        state: false,
        propertyName: 'cardNumberAuthentication'
      },
      {
        name: i18n.t('Marca'),
        state: false,
        propertyName: 'brandAuthentication'
      },
      {
        name: i18n.t('Enmascarada'),
        state: false,
        propertyName: 'maskTypeAuthentication'
      },
      {
        name: i18n.t('En claro'),
        state: false,
        propertyName: 'inclearTypeAuthentication'
      },
      {
        name: i18n.t('BIN'),
        state: false,
        propertyName: 'binAuthentication'
      },
      {
        name: i18n.t('LAST PAN'),
        state: false,
        propertyName: 'lastPanAuthentication'
      },
      {
        name: i18n.t('Moneda'),
        state: false,
        propertyName: 'currencyAuthentication'
      },
      {
        name: i18n.t('ECI'),
        state: false,
        propertyName: 'eciAuthentication'
      },
      {
        name: i18n.t('Monto Min'),
        state: false,
        propertyName: 'amountMinAuthentication'
      },
      {
        name: i18n.t('Monto Max'),
        state: false,
        propertyName: 'amountMaxAuthentication'
      },
      {
        name: i18n.t('Estado de Tarjeta'),
        state: false,
        propertyName: 'statusCardAuthentication'
      },
      {
        name: i18n.t('Canal de compra'),
        state: false,
        propertyName: 'channelAuthentication'
      },
      {
        name: i18n.t('Tipo de autenticación'),
        state: false,
        propertyName: 'authTypeAuthentication'
      },
      {
        name: i18n.t('Versión'),
        state: false,
        propertyName: 'authValueVersion'
      },
      {
        name: i18n.t('sort'),
        state: false,
        propertyName: 'sort'
      }
    ],
    isSearching: false,
    authenticationsList: null,
    responseAuthenticationsList: null,
    isLoadedAuthentications: false,
    responseAuthenticationInfo: null,
    isLoadedGetInfoAuthentication: false,
    pageCurrent: 1,
    fieldsFiltereds: {},
    changePage: false,
    searchingFilters: false,
    isTransformedData: false,
    detailCardNumber: '',
    objFiltered: {
      fromDateAuthentication: '',
      toDateAuthentication: '',
      authResultAuthentication: '',
      eciAuthentication: '',
      currencyAuthentication: '',
      amountMinAuthentication: '',
      amountMaxAuthentication: '',
      codeAuthentication: '',
      brandAuthentication: '',
      maskTypeAuthentication: 'Enmascarada',
      inclearTypeAuthentication: '',
      binAuthentication: '',
      lastPanAuthentication: '',
      cardNumberAuthentication: '',
      statusCardAuthentication: '',
      nameAuthentication: '',
      channelAuthentication: '',
      authTypeAuthentication: '',
      authValueVersion: ''
    },
    pageCurrentTransactions: 1,
    responseTransactionsByCard: null,
    isLoadingTransactionsByCard: false,
    tabListTransactionsByCard: null,
    exporting: false,
    responseExport: null,
    orderBy: {
      field: '',
      direction: ''
    }
  }
}

export const defaultValuesOperation = () => {
  return {
    filtersSpanish: [
      {
        name: i18n.t('Fecha de inicio'),
        state: false,
        propertyName: 'fromDateOperation'
      },
      {
        name: i18n.t('Fecha de fin'),
        state: false,
        propertyName: 'toDateOperation'
      },
      {
        name: i18n.t('Marca'),
        state: false,
        propertyName: 'brandOperation'
      },
      {
        name: i18n.t('Enmascarada'),
        state: false,
        propertyName: 'maskTypeAuthentication'
      },
      {
        name: i18n.t('En claro'),
        state: false,
        propertyName: 'inclearTypeAuthentication'
      },
      {
        name: i18n.t('BIN'),
        state: false,
        propertyName: 'binAuthentication'
      },
      {
        name: i18n.t('LAST PAN'),
        state: false,
        propertyName: 'lastPanAuthentication'
      },
      {
        name: i18n.t('Número de tarjeta'),
        state: false,
        propertyName: 'cardNumberOperation'
      },
      {
        name: i18n.t('Tipo de operación'),
        state: false,
        propertyName: 'typeOperation'
      },
      {
        name: i18n.t('Estado actual de la tarjeta'),
        state: false,
        propertyName: 'cardCurrentStateOperation'
      }
    ],
    isSearching: false,
    operationsList: null,
    responseOperationsList: null,
    isLoadedOperations: false,
    responseOperationInfo: null,
    isLoadedGetInfoOperation: false,
    pageCurrent: 1,
    fieldsFiltereds: {},
    changePage: false,
    searchingFilters: false,
    detailCardNumber: '',
    objFiltered: {
      fromDateOperation: '',
      toDateOperation: '',
      brandOperation: '',
      maskTypeOperation: 'Enmascarada',
      inclearTypeOperation: '',
      binOperation: '',
      lastPanOperation: '',
      cardNumberOperation: '',
      typeOperation: '',
      cardCurrentStateOperation: ''
    },
    pageCurrentOperations: 1,
    responseOperationsByCard: null,
    isLoadingOperationsByCard: false,
    tabListOperationsByCard: null,
    exporting: false,
    responseExport: null,
    orderBy: {
      field: '',
      direction: ''
    },
  }
}

export const defaultValuesWhitelist = () => {
  return {
    filtersSpanish: [
      {
        name: i18n.t('Comercio'),
        state: false,
        propertyName: 'commerceName'
      },
      {
        name: i18n.t('Código de comercio'),
        state: false,
        propertyName: 'acquirerID'
      },
      {
        name: i18n.t('Estado'),
        state: false,
        propertyName: 'stateCommerce'
      },
      {
        name: i18n.t('Fecha y hora de adición'),
        state: false,
        propertyName: 'fromDateCreatedAt'
      },
      {
        name: i18n.t('Fecha y hora de eliminación'),
        state: false,
        propertyName: 'fromDateDeletedAt'
      },
      {
        name: i18n.t('Actualizado por'),
        state: false,
        propertyName: 'user'
      }
    ],
    isSearching: false,
    pageCurrent: 1,
    changePage: false,
    searchingFilters: false,
    detailCardNumber: '',
    pageCurrentWhiteList: 1,
    responseWhiteListByCard: null,
    isLoadingWhiteListByCard: false,
    tabListWhiteListByCard: null,
    orderBy: {
      field: '',
      direction: ''
    },
  }
}

export const defaultValuesCard = () => {
  return {
    filtersSpanish: [
      {
        name: i18n.t('Marca'),
        state: false,
        propertyName: 'brandCard'
      },
      {
        name: i18n.t('Número de tarjeta'),
        state: false,
        propertyName: 'numberCard'
      },
      {
        name: i18n.t('Enmascarado'),
        state: false,
        propertyName: 'maskTypeCard'
      },
      {
        name: i18n.t('En claro'),
        state: false,
        propertyName: 'inclearTypeCard'
      },
      {
        name: i18n.t('BIN'),
        state: false,
        propertyName: 'binCard'
      },
      {
        name: i18n.t('LAST PAN'),
        state: false,
        propertyName: 'lastPanCard'
      },
      {
        name: i18n.t('Fecha de inicio'),
        state: false,
        propertyName: 'fromDateCard'
      },
      {
        name: i18n.t('Fecha de fin'),
        state: false,
        propertyName: 'toDateCard'
      },
      {
        name: i18n.t('Canal de enrolamiento'),
        state: false,
        propertyName: 'enrollChannelCard'
      },
      {
        name: i18n.t('Estado de la tarjeta'),
        state: false,
        propertyName: 'statusCard'
      }
    ],
    isSearching: true,
    cardsList: null,
    responseCardsList: null,
    isLoadedCards: false,
    responseCardInfo: null,
    isLoadedGetInfoCard: false,
    pageCurrent: 1,
    fieldsFiltereds: {},
    changePage: false,
    searchingFilters: false,
    objFiltered: {
      brandCard: '',
      versionCard:'',
      numberCard: '',
      maskTypeCard: 'Enmascarada',
      inclearTypeCard: '',
      binCard: '',
      lastPanCard: '',
      fromDateCard: '',
      toDateCard: '',
      enrollChannelCard: '',
      statusCard: ''
    },
    newCard: {
      card: "",
      brand_one_letter: "",
      brand_name: "blank",
      authentication_method: "",
      country_code: "",
      phone: "",
      email: ""
    },
    addingCard: false,
    addedCard: false,
    responseAddCard: null,
    addCardLoaded: false,
    isLoadingCard: false,
    errorMessage: '',
    exporting: false,
    responseExport: null,
    orderBy: {
      field: '',
      direction: ''
    }
  }
}

export const defaultValuesEnroll = () => {
  return {
    filtersSpanish: [
      {
        name: i18n.t('Fecha de inicio'),
        state: false,
        propertyName: 'fromDateEnroll'
      },
      {
        name: i18n.t('Fecha de fin'),
        state: false,
        propertyName: 'toDateEnroll'
      },
      {
        name: i18n.t('Nombre del archivo'),
        state: false,
        propertyName: 'nameEnroll'
      },
      {
        name: i18n.t('Estado'),
        state: false,
        propertyName: 'statusEnroll'
      },
    ],
    isSearching: true,
    enrollList: null,
    responseEnrollList: null,
    isLoadedEnroll: false,
    responseEnrollInfo: null,
    pageCurrent: 1,
    fieldsFiltereds: {},
    changePage: false,
    searchingFilters: false,
    objFiltered: {
      fromDateEnrollment: '',
      toDateEnrollment: '',
      nameFileEnrollment: '',
      statusEnrollment: '',
    },
    fileUploaded: {
      file: {}
    },
    isCorrectFormat: null,
    uploadlingPercent: 0,
    responseUploadFile: null,
    sentFromInput: false,
    responseGetUrlUploadFile: null,
    disabledUploadFileIsLoaded: true,
    responseGetListWithAllFilters: null,
    getListWithAllFiltersIsLoaded: false,
    responseFileSentToServer: null,
    responseGetUrlFile: null,
    uploadFileCompleted: false,
    uploadling: false,
    filtersEnroll: null,
    orderBy: {
      field: '',
      direction: ''
    }
  }
}

export const defaultValuesUsers = () => {
  return {
    filtersSpanish: [
      {
        name: i18n.t('Fecha de inicio'),
        state: false,
        propertyName: 'fromDateUser'
      },
      {
        name: i18n.t('Fecha de fin'),
        state: false,
        propertyName: 'toDateUser'
      },
      {
        name: i18n.t('Nombre'),
        state: false,
        propertyName: 'name'
      },
      {
        name: i18n.t('Apellido'),
        state: false,
        propertyName: 'lastName'
      },
      {
        name: i18n.t('Correo'),
        state: false,
        propertyName: 'email'
      },
      {
        name: i18n.t('Rol'),
        state: false,
        propertyName: 'role'
      },
      {
        name: i18n.t('Estado'),
        state: false,
        propertyName: 'state'
      }
    ],
    updateInfoUserUI: {
      name: '',
      lastName: '',
      countryCode: '',
      phone: ''
    },
    isUpdateInfoActiveUser: false,
    isChanging: false,
    newState: '',
    responseUserCancelInvitation: null,
    userIsCancelInvitation: false,
    userIsCancelInvitationLoading: false,
    responseUserReSentInvitation: null,
    userIsReSentInvitation: false,
    userIsReSentInvitationLoading: false,
    responseUserDelete: null,
    userIsDeleted: false,
    userIsDeletedLoading: false,
    responseUserImageUpdated: null,
    userImageIsChanged: false,
    userImageIsChanging: false,
    responseUpdatedUserInfo: null,
    isUpdatedUserInfo: false,
    isUpdatedUserInfoLoading: false,
    pageCurrentDashboard: 1,
    changePageDashboard: false,
    pageCurrent: 1,
    responseAssociateDetail: null,
    changePage: false,
    associateDetailIsloaded: false,
    objUsersFiltered: {
      fromDateUser: '',
      toDateUser: '',
      name: '',
      lastName: '',
      email: '',
      role: '',
      state: ''
    },
    responseListAllUsers: null,
    listAllUsers: [],
    allUserLoaded: false,
    allUserLoading: false,
    userInfoLoaded: false,
    responseUserInfo: null,
    userInvited: {
      email: '',
      role: ''
    },
    responseSentInvitation: null,
    sendingInvitation: false,
    user: {
      email: '',
      state: '',
      role: '',
      dateRegistry: '',
      dateUpdated: '',
      lastUpdateBy: ''
    },
    userProfileData: {
      name: '',
      lastname: '',
      countryCode: '',
      country: '',
      phone: '',
      email: ''
    },
    userProfileDataUpdating: false,
    userProfileDataUpdated: false,
    userProfileDataResponse: null,
    userProfileDataResponseMessage: '',
    userProfileDataUpdatedValid: false,
    userProfilePassword: {
      current: '',
      newPassword: '',
      againPassword: ''
    },
    userProfilePasswordUpdating: false,
    userProfilePasswordUpdated: false,
    userProfilePasswordResponse: null,
    userProfilePasswordResponseMessage: '',
    userProfilePasswordUpdatedValid: false,
    orderBy: {
      field: '',
      direction: ''
    }
  }
}

export const monthNames = () => ([
  i18n.t("Enero"),
  i18n.t("Febrero"),
  i18n.t("Marzo"),
  i18n.t("Abril"),
  i18n.t("Mayo"),
  i18n.t("Junio"),
  i18n.t("Julio"),
  i18n.t("Agosto"),
  i18n.t("Septiembre"),
  i18n.t("Octubre"),
  i18n.t("Noviembre"),
  i18n.t("Diciembre")
]);

export const defaultValuesStats = () => ({
  responseStatistics: null,
  isLoadingStatistics: false,
  listStatistics: null,
  listStatisticsTotal: null,
})

export const defaultValuesMerchants = () => ({
  responseCurrenciesByEditMerchant: null,
  currenciesUpdatedEditMerchant: null,
  currenciesUpdatedEditMerchantLoaded: false,
  showModalConfirmUpdate: false,
  updateLoading: false,
  filtersSpanish: [
    {
      name: i18n.t('Fecha de inicio'),
      state: false,
      propertyName: 'fromDate'
    },
    {
      name: i18n.t('Fecha de fin'),
      state: false,
      propertyName: 'toDate'
    },
    {
      name: i18n.t('Nombre de comercio'),
      state: false,
      propertyName: 'merchantName'
    },
    {
      name: i18n.t('Código de tienda'),
      state: false,
      propertyName: 'merchantCode'
    },
    {
      name: i18n.t('Estado'),
      state: false,
      propertyName: 'state'
    },
    {
      name: i18n.t('Marca'),
      state: false,
      propertyName: 'brand'
    }
  ],
  fieldsFiltereds: {},
  changePage: false,
  pageCurrent: 1,
  uploadFileCompleted: false,
  responseGetUrlFile: null,
  showModalStatusRegister: null,
  responseRegisterNewMerchantIsLoaded: false,
  responseRegisterNewMerchant: null,
  responseEditedMerchant: null,
  isLoadedMerchant: false,
  listCurrenciesSelecteds: [],
  currencySelectedByEdit: {},
  responseGetMerchantInfo: {},
  responseGetMerchantInfoIsLoaded: false,
  file: {},
  fields: {},
  responseFileSentToServer: null,
  responseGetUrlUploadFile: null,
  responseGetListWithAllFilters: null,
  getListWithAllFiltersIsLoaded: false,
  disabledUploadFileIsLoaded: true,
  responseUploadFile: null,
  uploading: false,
  uploadlingPercent: 0,
  uploadling: false,
  isCorrectFormat: null,
  sentFromInput: false,
  showForm: false,
  responseResultFilters: {},
  filter: {
    fromDate: '',
    toDate: '',
    merchantName: '',
    merchantCode: '',
    state: '',
    brand: ''
  },
  filtersBulkLoad: {
    fromDate: '',
    toDate: '',
    fileName: '',
    stateBatch: '',
    stateProcess: ''
  },
  merchantsLoaded: false,
  newRegister: {
    commerce_name: '',
    merchant_category_code: '',
    merchant_information: {
    },
    country: '',
    notification_url: '',
    protocol:{},
    version_protocol: '',
    brands: [],
    date_registry: "",
		date_update: "",
		last_update_by: "",
  },
  allBrands: [],
  allCurrencs: [],
  brandsSelected: [],
  brandSelected: {
    position: null,
    code_commerce: "",
		code: "",
		name: "",
		enabled: false,
		currencies: []
  },
  allCurrencies: {
    visa: [],
    mscd: [],
    dinc: [],
    amex: []
  },
  allCodes: {
    0: '',
    1: '',
    2: '',
    3: ''
  },
  currencySelected: {
    numeric_code: "",
    iso_code: "",
    enabled: false
  },
  currenciesSelecteds: [],
  isUpdatedWithBrands: false,
  fileUploaded: {
    file: {}
  }
})

export const COUNTRIES = () => ([
	{
		value: i18n.t('PERU')
	},
	{
		value: i18n.t('CHILE')
	},
	{
		value: i18n.t('MEXICO')
	},
	{
		value: i18n.t('USA')
	},
	{
		value: i18n.t('BOLIVIA')
	}
])

export const VERSIONS_PROTOCOL = () => ([
	{
		value: '2.1.0'
	},
	{
		value: '2.2.0'
	}
])

export const SCHEMA_SECURITY = () => ([
	{
		value: '2.1.0'
	},
	{
		value: '2.2.0'
	}
])

export const PAYMENTS_METHODS = () => ([
	{
		selected: false,
    type: 'MSCD',
    name: 'mastercard',
    urlImg: 'mastercard',
    urlImgGray: 'mastercard-gray'
	},
	{
		selected: false,
    type: 'VISA',
    name: 'visa',
		urlImg: 'visa',
    urlImgGray: 'visa-gray'
	},
	{
		selected: false,
		type: 'DINC',
    name: 'dinner',
	  urlImg: 'dinner',
    urlImgGray: 'dinner-gray'
	},
	{
		selected: false,
		type: 'AMEX',
    name: 'amex',
		urlImg: 'amex',
    urlImgGray: 'amex-gray'
	}
])

export const columns = () => ([
    {
      title: i18n.t('COMERCIO'),
      width: 0,
      dataIndex: 'merchantName',
      key: 'merchantName',
      fixed: 'left',
    },
    {
      title: i18n.t('CODIGO COMERCIO'),
      width: 200,
      dataIndex: 'merchantCode',
      key: 'merchantCode',
    },
    {
      title: i18n.t('NÚMERO DE PEDIDO'),
      width: 205,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: i18n.t('NÚMERO DE TARJETA'),
      width: 220,
      dataIndex: 'cardNumber',
      key: 'cardNumber',
    },
    {
      title: i18n.t('IMPORTE'),
      width: 105,
      dataIndex: 'import',
      key: 'import',
    },
    {
      title: i18n.t('RESULTADO'),
      width: 200,
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: i18n.t('ECI'),
      width: 65,
      dataIndex: 'eci',
      key: 'eci',
    }
  ]);

export const data = [
    {
      key: '1',
      name: 'John Brow1n',
      age: 32,
      address: 'New York Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '2',
      name: 'Jim Gree2n',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '3',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '4',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '5',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '6',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '7',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '8',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '9',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '10',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '11',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '12',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '13',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '14',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '15',
      name: 'Jim Green',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    },
    {
      key: '16',
      name: 'Jim GreeDSADSADASDn',
      age: 40,
      address: 'London Park',
      import: 32,
      result: 32,
      eci: 'New York Park',
    }
];

export const OPTIONS_RESULT_AUTH = () => ([
    {
      value: i18n.t('Autenticación Denegada'),
      identifier: 'Autenticación Denegada'
    },
    {
      value: i18n.t('Autenticación Exitosa'),
      identifier: 'Autenticación Exitosa'
    },
    {
      value: i18n.t('Autenticación Fallida'),
      identifier: 'Autenticación Fallida'
    },
    {
      value: i18n.t('Autenticación Incompleta'),
      identifier: 'Autenticación Incompleta'
    },
		{
			value: i18n.t('Todos'),
      identifier: 'Todos'
		},
])

export const OPTIONS_CURRENCY = () => ([
		{
			value: '00',
      description: i18n.t('Todos')
		},
		{
			value: '604',
      description: i18n.t('Soles')
		},
		{
			value: '840',
      description: i18n.t('Dólares')
		}
])

export const OPTIONS_PURCHASE_CHANNEL = () => ([
    {
			value: '01',
      description: i18n.t('Mobile')
		},
		{
			value: '02',
      description: i18n.t('Web')
		},
		{
			value: '00',
      description: i18n.t('Todos')
		}
])

export const OPTIONS_CARD_STATUS = () => ([
		{
			value: '01',
      description: i18n.t('Afiliado')
		},
		{
			value: '02',
      description: i18n.t('Desafiliado')
		},
    {
			value: '03',
      description: i18n.t('Bloqueado')
		}
])

export const OPTIONS_BRANDS = () => ([
    {
      code: 'AMERICAN_EXPRESS',
			value: 'American Express'
		},
		{
      code: 'DINERS',
			value: 'Diners Club'
    },
    {
      code: 'MASTERCARD',
			value: 'Mastercard'
		},
    {
      code: 'VISA',
			value: 'Visa'
    }
])

export const OPTIONS_OPERATION_STATUS = () => ([
    {
      code: '01',
			value: i18n.t('Afiliación')
		},
    {
      code: '02',
			value: i18n.t('Desafiliación')
    },
		{
      code: '03',
			value: i18n.t('Actualización')
    },
		{
      code: '04',
			value: i18n.t('Bloqueo')
    },
		{
      code: '05',
			value: i18n.t('Desbloqueo')
    }
])

export const OPTIONS_OPERATION_CARD_STATUS = () => ([
    {
      code: '01',
			value: i18n.t('Afiliado')
		},
		{
      code: '02',
			value: i18n.t('Desafiliado')
    },
		{
      code: '03',
			value: i18n.t('Bloqueado')
    }
])

export const ENROLLMENT_STATUS = () => ([
    {
      code: '01',
      value: i18n.t('En Proceso')
    },
    {
      code: '02',
      value: i18n.t('Procesado con éxito')
    },
    {
      code: '03',
      value: i18n.t('Procesado con error')
    },
    {
      code: '04',
      value: i18n.t('Todos')
    }
])
  
export const COLUMNS_MERCHANTS_TABLE = () => ([
    {
      title: i18n.t('NOMBRE'),
      width: 0,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: i18n.t('CODIGO DE COMERCIO'),
      width: 90,
      dataIndex: 'mcc',
      key: 'mcc',
    },
    {
      title: i18n.t('ESTADO'),
      width: 50,
      dataIndex: 'version_protocol',
      key: 'version_protocol',
    },
    {
      title: i18n.t('MARCA'),
      width: 50,
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: i18n.t('FECHA Y HORA DE REGISTRO'),
      width: 105,
      dataIndex: 'notificationURL',
      key: 'notificationURL',
    }
])

export const RESPONSE_LIST_MERCHANTS = {
    "action": "list_merchant",
    "success": true,
    "data": [
        {
            "name": "Xiaomi.mi",
            "mcc": "C12345",
            "country": "022",
            "notificationURL": "https://www.visa.com.pe/",
            "version_protocol": "2.1.0",
            "brand": "visa"
        },
        {
          "name": "Comercio",
          "mcc": "C12346",
          "country": "026",
          "notificationURL": "https://www.visa.com.pe/",
          "version_protocol": "2.1.0",
          "brand": "Mastercard"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      },
      {
        "name": "Rosatel",
        "mcc": "C12347",
        "country": "042",
        "notificationURL": "https://www.visa.com.pe/",
        "version_protocol": "2.1.0",
        "brand": "Amex"
      }
    ],
    "merchant": {
        "meta": {
            "status": {
                "code": "00",
                "message_ilgn": [
                    {
                        "locale": "es_PE",
                        "value": "Exito al mostrar los comercios"
                    }
                ]
            }
        }
    }
}

export const STATES_FILTER = () => ([
  {
    value: i18n.t('Terminado'),
    key: '02'
  },
  {
    value: i18n.t('Error en proceso'),
    key: '01'
  }
])

export const RESET_OBJ_MERCHANTS = () => ({
    id: {
      value:'',
      hidden:true
    },
    name: {
      value:'',
    },
    code:{
      value:'',
      type:'link',
      link:'/merchant/edit/123456'
    },
    status:{
      value:''
    },
    brand:{
      value:''
    },
    date:{
      value:''
    }
})

export const ROLES = {
  administrador: {
    issuerHeader: {
      updateIssuerPic: true
    },
    modules: {
      queries: {
        isPermitted: true,
        queryAuthentication: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        },
        queryOperation: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        }
      },
      cardManagement: {
        isPermitted: true,
        cardQueries: {
          isPermitted: true,
          btnDownload: true,
          btnAdd: true,
          btnFilter: true,
          detail: {
            isPermitted: true,
            tabOperation: true,
            tabAuthentication: true,
            tabWhiteList:true,
            chkOtp: true,
            chkSms: true,
            chkEmail: true,
            btnUpdate: true,
            btnDemission: true,
          }
        },
        massiveEnroll: {
          isPermitted: true,
        },
        keysManagement: {
          isPermitted: true,
        }
      },
      users: {
        isPermitted: false,
        btnInvite: false,
        btnFilter: false,
        detail: {
          isPermitted: false,
          email: false,
          state: false,
          role: false,
          btnUpdate: false,
          btnResendInvitation: false,
          btnCancelInvitation: false,
          btnDelete: false,
          tabAssociationDetail: false,
        }
      },
      statistics: {
        isPermitted: true,
      },
      info: {
        isPermitted: true,
      }
    }
  },
  visor: {
    issuerHeader: {
      updateIssuerPic: true
    },
    modules: {
      queries: {
        isPermitted: true,
        queryAuthentication: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        },
        queryOperation: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        }
      },
      cardManagement: {
        isPermitted: true,
        cardQueries: {
          isPermitted: true,
          btnDownload: true,
          btnAdd: false,
          btnFilter: true,
          detail: {
            isPermitted: true,
            tabOperation: true,
            tabAuthentication: true,
            chkOtp: false,
            chkSms: false,
            chkEmail: false,
            btnUpdate: false,
            btnDemission: false,
          }
        },
        massiveEnroll: {
          isPermitted: false,
        },
        keysManagement: {
          isPermitted: false,
        }
      },
      users: {
        isPermitted: false,
        btnInvite: false,
        btnFilter: false,
        detail: {
          isPermitted: false,
          email: false,
          state: false,
          role: false,
          btnUpdate: false,
          btnResendInvitation: false,
          btnCancelInvitation: false,
          btnDelete: false,
          tabAssociationDetail: false,
        }
      },
      statistics: {
        isPermitted: true,
      },
      info: {
        isPermitted: true,
      }
    }
  },
  enrolador: {
    issuerHeader: {
      updateIssuerPic: false
    },
    modules: {
      queries: {
        isPermitted: true,
        queryAuthentication: {
          isPermitted: false,
          btnDownload: false,
          btnFilter: false,
          detail: false,
        },
        queryOperation: {
          isPermitted: true,
          btnDownload: false,
          btnFilter: true,
          detail: true,
        }
      },
      cardManagement: {
        isPermitted: true,
        cardQueries: {
          isPermitted: true,
          btnDownload: true,
          btnAdd: true,
          btnFilter: true,
          detail: {
            isPermitted: true,
            tabOperation: true,
            tabAuthentication: false,
            chkOtp: true,
            chkSms: true,
            chkEmail: true,
            btnUpdate: true,
            btnDemission: true,
          }
        },
        massiveEnroll: {
          isPermitted: true,
        },
        keysManagement: {
          isPermitted: false,
        }
      },
      users: {
        isPermitted: false,
        btnInvite: false,
        btnFilter: false,
        detail: {
          isPermitted: false,
          email: false,
          state: false,
          role: false,
          btnUpdate: false,
          btnResendInvitation: false,
          btnCancelInvitation: false,
          btnDelete: false,
          tabAssociationDetail: false,
        }
      },
      statistics: {
        isPermitted: false,
      },
      info: {
        isPermitted: false,
      }
    }
  },
  operativo: {
    issuerHeader: {
      updateIssuerPic: false
    },
    modules: {
      queries: {
        isPermitted: true,
        queryAuthentication: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        },
        queryOperation: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        }
      },
      cardManagement: {
        isPermitted: false,
        cardQueries: {
          isPermitted: false,
          btnDownload: false,
          btnAdd: false,
          btnFilter: false,
          detail: {
            isPermitted: false,
            tabOperation: false,
            tabAuthentication: false,
            chkOtp: false,
            chkSms: false,
            chkEmail: false,
            btnUpdate: false,
            btnDemission: false,
          }
        },
        massiveEnroll: {
          isPermitted: false,
        },
        keysManagement: {
          isPermitted: false,
        }
      },
      users: {
        isPermitted: false,
        btnInvite: false,
        btnFilter: false,
        detail: {
          isPermitted: false,
          email: false,
          state: false,
          role: false,
          btnUpdate: false,
          btnResendInvitation: false,
          btnCancelInvitation: false,
          btnDelete: false,
          tabAssociationDetail: false,
        }
      },
      statistics: {
        isPermitted: true,
      },
      info: {
        isPermitted: false,
      }
    }
  },
  seguridad: {
    issuerHeader: {
      updateIssuerPic: false
    },
    modules: {
      queries: {
        isPermitted: false,
        queryAuthentication: {
          isPermitted: false,
          btnDownload: false,
          btnFilter: false,
          detail: false,
        },
        queryOperation: {
          isPermitted: false,
          btnDownload: false,
          btnFilter: false,
          detail: false,
        }
      },
      cardManagement: {
        isPermitted: false,
        cardQueries: {
          isPermitted: false,
          btnDownload: false,
          btnAdd: false,
          btnFilter: false,
          detail: {
            isPermitted: false,
            tabOperation: false,
            tabAuthentication: false,
            chkOtp: false,
            chkSms: false,
            chkEmail: false,
            btnUpdate: false,
            btnDemission: false,
          }
        },
        massiveEnroll: {
          isPermitted: false,
        },
        keysManagement: {
          isPermitted: false,
        }
      },
      users: {
        isPermitted: true,
        btnInvite: true,
        btnFilter: true,
        detail: {
          isPermitted: true,
          email: true,
          state: true,
          role: true,
          btnUpdate: true,
          btnResendInvitation: true,
          btnCancelInvitation: true,
          btnDelete: true,
          tabAssociationDetail: true,
        }
      },
      statistics: {
        isPermitted: false,
      },
      info: {
        isPermitted: false,
      }
    }
  },
  visorAlignet: {
    issuerHeader: {
      updateIssuerPic: true
    },
    modules: {
      queries: {
        isPermitted: true,
        queryAuthentication: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        },
        queryOperation: {
          isPermitted: true,
          btnDownload: true,
          btnFilter: true,
          detail: true,
        }
      },
      cardManagement: {
        isPermitted: true,
        cardQueries: {
          isPermitted: true,
          btnDownload: true,
          btnAdd: false,
          btnFilter: true,
          detail: {
            isPermitted: true,
            tabOperation: true,
            tabAuthentication: true,
            chkOtp: false,
            chkSms: false,
            chkEmail: false,
            btnUpdate: false,
            btnDemission: false,
          }
        },
        massiveEnroll: {
          isPermitted: false,
        },
        keysManagement: {
          isPermitted: false,
        }
      },
      users: {
        isPermitted: false,
        btnInvite: false,
        btnFilter: false,
        detail: {
          isPermitted: false,
          email: false,
          state: false,
          role: false,
          btnUpdate: false,
          btnResendInvitation: false,
          btnCancelInvitation: false,
          btnDelete: false,
          tabAssociationDetail: false,
        }
      },
      statistics: {
        isPermitted: true,
      },
      info: {
        isPermitted: true,
      }
    }
  },
}

export const OPTIONS_AUTH = () => ([
  {
    value: i18n.t('Autenticación Desacoplada'),
    identifier: 'Autenticación Desacoplada'
  },
  {
    value: i18n.t('Autenticación con Desafío'),
    identifier: 'Autenticación con Desafío'
  },
  {
    value: i18n.t('Autenticación Sin Fricción'),
    identifier: 'Autenticación Sin Fricción'
  },
  {
    value: i18n.t('Todos'),
    identifier: 'Todos'
  }
])

export const VALUES_VERSION = () => ([
{
  value: i18n.t('2.1.0'),
  identifier: '2.1.0'
},
{
  value: i18n.t('2.2.0'),
  identifier: '2.2.0'
},
{
  value: i18n.t('Todos'),
  identifier: 'Todos'
}
])